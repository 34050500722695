import React from 'react';
import "./CybersecurityAwarenessPage.css";
import CybersecurityComp from '../../../Components/UserComp/CybersecurityComp/CybersecurityComp';

const CybersecurityAwarenessPage = () => {
    return (
        <div>
            <CybersecurityComp></CybersecurityComp>
        </div>
    );
};

export default CybersecurityAwarenessPage;