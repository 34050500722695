import React, { useState } from "react";
import Styles from "./ServicesComp.module.css";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeader from "../../CommonComps/CommonHeader/CommonHeader";
import ServicesHeader from "./ServicesHeader/ServicesHeader";
import SolutionDev from "./SolutionDev/SolutionDev";
import { useNavigate } from "react-router-dom";
import Consultancy from "./Consultancy/Consultancy";
import AssessmentAssurance from "./AssessmentAssurance/AssessmentAssurance";
import InformationSecurity from "./InformationSecurity/InformationSecurity";
import TrainingAndDev from "./TrainingAndDev/TrainingAndDev";
import ItServices from "./ItServices/ItServices";
import ScrollableTab from "./ScrollableTab ";
import CommonHeader2 from "../../CommonComps/CommonHeader/CommonHeader2";

const servicesData = [
  {
    id: 1,
    title: "Solution Development",
    description:
      "We use cutting edge technology to development responsive solutions for web and mobile platform within justified budget. ",
    pageContent: <SolutionDev />,
  },
  {
    id: 2,
    title: "Consultancy",
    description:
      "We have certified and experienced Consultant Team who can help to assess business processes and IT investment and help you to align them with business objective. ",
    pageContent: <Consultancy />,
  },
  {
    id: 3,
    title: "Assessment and Assurance",
    description:
      "Regardless of what your business, TechSimple ICT will help you to identify risks and set direction to secure IT environment to fulfill business objective. ",
    pageContent: <AssessmentAssurance />,
  },
  {
    id: 4,
    title: "Information Security",
    description:
      "Information is very important asset for your business and that should be secured properly. TechSimple ICT can help you to protect business Information. ",
    pageContent: <InformationSecurity />,
  },
  {
    id: 5,
    title: "Training and development",
    description:
      "Training is required both for individual and organization to ensure continuous growth and improvement. We are here to share our expertise with you or your business.",
    pageContent: <TrainingAndDev />,
  },
  {
    id: 6,
    title: "IT Managed Service",
    description:
      "TechSimple ICT helps businesses by taking responsibility for IT Operation, maintenance and delivery for the business. ",
    pageContent: <ItServices />,
  },
];

const ServicesComp = () => {
  const [activeBtn, setActiveBtn] = useState(servicesData[0].id);
  const [headerInfo, setHeaderInfo] = useState(servicesData[0]);

  const navigate = useNavigate();


  return (
    <div>
      <Container className="pt-3 mt-40">
      <CommonHeader2
        // shadowTitle="SERVICES"
        title="Our Services"
        description="We serve the right solution to build better digital world"
      />
      {/* Render the appropriate component based on the id */}
      {servicesData.map((item) => (
        <ScrollableTab key={item.id} item={item} bg='bg-[#fff]'>
          
        </ScrollableTab>
      ))}
    </Container>
    </div>
  );
};

export default ServicesComp;
