import React from "react";
import { Container } from "react-bootstrap";

const SecurityServicesHeader = ({ title, description }) => {
  return (
    <div
      style={{ backgroundColor: "#F1FAFF", margin: "0px 0px" }}
      className="text-center py-4 border rounded-lg"
    >
      <Container>
        <h2 className="text-3xl">{title}</h2>
        <p>{description}</p>
      </Container>
    </div>
  );
};

export default SecurityServicesHeader;
