import { useState } from "react";
import useTitle from "../../../hooks/useTitle";
import Loader from "../../CommonComps/Loader/Loader";
import './AwarnessOneComp.css'

const AwarnessDataEntryComp = () => {
  useTitle("Awarness");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleSaveData = async (event) => {
    event.preventDefault();
    const form = event.target;

    const title = form.title.value;
    const link = form.link.value;
    const description = form.description.value;

    const awarnessData = {
      title, link, description
    };

    // console.log("member Image Data : ", member);

    const imageFile = form.image.files[0];
    const formData = new FormData();
    formData.append('image', imageFile);

    const apiUrl = process.env.REACT_APP_SERVER_ADDRESS + '/api/add-awarness';

    try {
      // setLoading(true);
      const imageResponse = await fetch(
        'https://api.imgbb.com/1/upload?key=7a43c068c4477f76ae69e0549062c80e',
        {
          method: 'POST',
          body: formData,
        }
      );

      const imageData = await imageResponse.json();
      if (imageData.data && imageData.data.display_url) {
        awarnessData.image = imageData.data.display_url;

        const createAwarnessResponse = await fetch(`${apiUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(awarnessData),
        });

        const createAwarnessResult = await createAwarnessResponse.json();
        // console.log(createAwarnessResult);
        window.location.reload();
      } else {
        // console.log("Error uploading image");
      }
    } catch (error) {
      // console.error("An error occurred:", error);
    }
  };


  if (loading || uploading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="container">
      <div className="mb-2">
        <div className="col-lg-10 mx-auto">
          <h2 className='py-3 text-center fw-bold'>Enter Awarness Data</h2>
          <div className="regForm col-lg-6 col-md-9 mx-auto p-md-4 p-2">
            <form onSubmit={handleSaveData}>

              <div className="mb-3">
                <label htmlFor="title" className="form-label fw-bold mb-0">Title<span className="star">&#x2605;</span></label>
                <input type="text" name="title" className="form-control" id="title" placeholder="Enter title here" required />
              </div>

              <div className="mb-3">
                <label htmlFor="link" className="form-label fw-bold mb-0">Link<span className="star">&#x2605;</span></label>
                <input type="text" name="link" className="form-control" id="link" placeholder="Enter link here" required />
              </div>

              <div className="mb-3">
                <label htmlFor="image" className="form-label fw-bold mb-0">Image<span className="star">&#x2605;</span></label>
                <input type="file" name="image" className="form-control" id="image" required />
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label fw-bold mb-0">Description</label>
                <textarea className="form-control" name="description" placeholder="Enter a description here" id="floatingTextarea" maxLength={200} />
              </div>

              <div className="d-flex justify-content-between">
                <button type="reset" className="btn btn-warning">Reset</button>
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwarnessDataEntryComp;