import React, { useEffect, useState, Suspense, lazy } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { AiOutlineStar } from 'react-icons/ai';
import techLogo1 from "../../../assets/Images/Logos/TechSimpleICT Logo-03 2 (1).png";
import CommonTitle from "../../CommonComps/CommonTitle/CommonTitle";
import Styles from "./AboutComp.module.css";
import useTitle from "../../../hooks/useTitle";
import { TbBulb } from "react-icons/tb";
import { MdAddToPhotos, MdBookOnline, MdOutlineBeenhere } from "react-icons/md";
import { IoBriefcase } from "react-icons/io5";
import { FaBuilding, FaHandHolding, FaHandsHelping } from "react-icons/fa";
import CommonHeader2 from "../../CommonComps/CommonHeader/CommonHeader2";
// import { motion } from 'framer-motion';
import ChooseUs from "../HomeComp/ChooseUs/ChooseUs";
import { SiEnterprisedb } from "react-icons/si";
import { SEO, StructuredData } from "../../../hooks/SEO";
import Loader from "../../CommonComps/Loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";


const workFakeData = [
  { title: "Community Based Organization like Club, Forum, Society, Association", icon: <FaHandsHelping /> },
  { title: "Startup and SME", icon: <TbBulb /> },
  {
    title: "Defense and Low Enforcement Agency", icon: <MdOutlineBeenhere />
  },
  { title: "NGOs", icon: <MdAddToPhotos /> },
  { title: "Enterprise", icon: <FaBuilding /> },
  { title: "Government", icon: <IoBriefcase /> },
];

const timelineElements = [
  {
    date: "2015",
    title: "Founded",
    subtitle: "Mission to innovate ICT solutions",
    description: "TechSimple ICT was founded with a mission to innovate ICT solutions."
  },
  {
    date: "2017",
    title: "Global Expansion",
    subtitle: "",
    description: "Expanded services globally to meet the growing demands."
  },
  {
    date: "2019",
    title: "Award Recognition",
    subtitle: "",
    description: "Recognized for excellence in IT consultancy."
  },
  {
    date: "2021",
    title: "Client Milestone",
    subtitle: "",
    description: "Reached 100+ satisfied clients worldwide."
  }
];

const AboutComp = () => {
  useTitle("About");

  // const [scrollProgress, setScrollProgress] = useState(0);

  // const handleScroll = () => {
  //   const scrollTop = document.documentElement.scrollTop;
  //   const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  //   const scrollPercentage = (scrollTop / scrollHeight) * 100;
  //   setScrollProgress(scrollPercentage);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);


  return (
    <div>
      <SEO
        title="About - TechSimple ICT"
        description="TechSimple ICT About Us Page"
        url="https://techsimpleict.com/aboutUs"
      />
      {/* <StructuredData /> */}
      <div className="progress-bar"
      // style={{ 
      //   // width: `${scrollProgress}%`, 
      //   height: "4px", backgroundColor: "#EB3539", position: "fixed", top: 0, left: 0, zIndex: 1000 }}
      ></div>

      <Suspense fallback={<div><Loader /></div>}>
        <Container className="py-3 mt-[125px]">
        {/* <CommonHeader2 shadowTitle="" title="About Us" /> */}
        <Row className="z ">
          <CommonTitle title="OUR VISION" />
          <Col md={3}>
            <LazyLoadImage height={250} offset={150}
             className={Styles.heroImg__style} src={techLogo1} alt="TechSimpleICT Logo" />
          </Col>
          <Col md={9}>
            <h2 className={`${Styles.heroHeading__style} text-md-start text-center mt-md-4`}>
              Serving business with{" "}
              <span style={{ color: "#eb3539", fontWeight: "bold" }}>BEST SOLUTION</span>{" "}
              to realize{" "}
              <span style={{ color: "#eb3539", fontWeight: "bold" }}>TRUE POTENTIAL</span>{" "}
              of ICT and{" "}
              <span style={{ fontWeight: "bold" }}>BUILD DIGITAL TRUST.</span>
            </h2>
          </Col>
        </Row>
      </Container>
        <div className="" style={{ backgroundColor: "#fff", padding: "0px 0px" }}>
          <Container className="flex flex-col justify-center items-center">
            <Row className={Styles.valueRow__style}>
              <CommonTitle title="OUR VALUE" />
              <Col md={12}>
                <div className="d-flex flex-column flex-md-row align-items-center justify-center">
                  <div className="text-center text-md-start">
                    <div className="py-2">
                      <h1 className="text-2xl border-b-2 inline-block border-b-red-500">Integrity</h1>
                      <h2 className="py-2">
                        We maintain integrity in all our processes. Strict Quality Management helps us deliver the best product.
                      </h2>
                    </div>
                    <div className="py-2">
                      <h4 className="text-2xl border-b-2 inline-block border-b-red-500">Social</h4>
                      <p className="py-2">
                        We are socially minded and work to build a respectful society.
                      </p>
                    </div>
                    <div className="py-2">
                      <h1 className="text-2xl border-b-2 inline-block border-b-red-500">Partnership</h1>
                      <h2 className="py-2">
                        We serve to grow our clients' businesses and work for them as partners.
                      </h2>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-0 ms-md-4 hidden md:block">
                  <LazyLoadImage height={250} offset={150}
                      className="w-52 md:w-100 max-w-sm md:w-auto"
                      src="https://ustedu.org/wp-content/uploads/2019/11/values.jpg"
                      alt="Values"
                      effect=""
                    />

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row className="pb-md-3 pb-0 mt-4 justify-center">
            <CommonTitle title="WE WORK FOR" />
            {workFakeData.map((item, index) => {
              const textColorClass = index % 2 === 0 ? Styles["text-blue"] : Styles["text-red"];
              const textColorClass2 = index % 2 === 0 ? Styles["text-red"] : Styles["text-blue2"];
              return (<Col
                className="text-center py-md-2 pt-md-4 p-3"
                key={index}
                lg={4} // 3 columns per row on large screens
                md={6} // 2 columns per row on medium screens
                sm={12} // 1 column per row on small screens
              >
                <div className="flex flex-col justify-center items-center rounded-2xl border border-gray-300 shadow-md group transition-transform duration-3 .0
      m-0.500 transform hover:scale-105 mt-1 h-64">
                  <p
                    className={`text-8xl ${index % 2 === 0 ? Styles["text-red"] : Styles["text-blue"]
                      } rounded-full p-4 group-hover:scale-110 duration-300 text-[#4f6da5] `}
                  >
                    {item.icon}
                  </p>
                  <p className={`font-bold text-lg md:text-xl text-center ${textColorClass}`}>
                    {item.title}
                  </p>
                </div>
              </Col>)

            })}
          </Row>

        </Container>


        {/* Interactive Timeline */}
        {/* <Container className="mt-5">
        <CommonTitle title="OUR JOURNEY" />
        <Row className="mt-4">
          <Col>
            <VerticalTimeline>
              {timelineElements.map((element, index) => (
                <VerticalTimelineElement
                  key={index}
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#EBF3FA', color: '#000' }}
                  contentArrowStyle={{ borderRight: '7px solid  #EBF3FA' }}
                  date={<span style={{ background: '#EBF3FA', padding: '5px 10px', borderRadius: '5px', color: '#000' }}>{element.date}</span>}
                  iconStyle={{ background: '#EB3539', color: '#fff' }}
                  icon={<AiOutlineStar />}
                >
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    data-tip={element.description}
                    data-for={`tooltip-${index}`}
                  >
                    <h3 className="vertical-timeline-element-title text-lg fw-bold">{element.title}</h3>
                    <h4 className="vertical-timeline-element-subtitle">{element.subtitle}</h4>
                    <p>
                      {element.description}
                    </p>
                  </motion.div>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </Col>
        </Row>
      </Container> */}

        <ChooseUs />
      </Suspense>


    </div>
  );
};

export default AboutComp;
