import "./App.css";

import { RouterProvider } from 'react-router-dom';
import router from "./routes/mainRouter";
import { Toaster } from "react-hot-toast";
import ScrollToTopButton from "./hooks/ScrollToTopBtn";
// import MessengerCustomerChat from 'react-messenger-customer-chat';

function App() {

  return (
    <div>

      <ScrollToTopButton />
      <Toaster />
      {/* <MessengerCustomerChat
        pageId="111316204703721"
        appId="976746150564521"
        htmlRef="<REF_STRING>"
      /> */}
      <RouterProvider router={router}></RouterProvider>
    </div>
    // <BrowserRouter>
    //   <NavbarComp />
    //   <Routes>
    //     <Route path="/" element={<HomePage />} />
    //     <Route path="/aboutUs" element={<AboutPage />} />
    //     <Route path="/contactUs" element={<ContactPage />} />
    //     <Route path="/services" element={<ServicesPage />} />
    //     <Route path="/training" element={<TrainingPage />} />
    //     <Route path="/registration" element={<RegistrationPage />} />
    //     <Route path="/regDetails" element={<RegistrationDetailsPage />} />
    //     <Route path="*" element={<NotFoundPage />} />
    //   </Routes>
    //   <Footer />
    // </BrowserRouter>
  );
}

export default App;
