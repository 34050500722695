import React from "react";
import ServicesComp from "../../../Components/UserComp/ServicesComp/ServicesComp";
import useTitle from "../../../hooks/useTitle";

const ServicesPage = () => {
  useTitle("Services");

  return (
    <div>
      <ServicesComp />
    </div>
  );
};

export default ServicesPage;
