import React from 'react';
import CISACourseDetailsBanner from './CISACourseDetailsBanner';
import CISACourseDescription from './CISACourseDescription';
import useTitle from '../../../../hooks/useTitle';

const CISACourseDetails = () => {
    useTitle("CISACourseDetails")
    return (
        <div className=''>
            <CISACourseDetailsBanner />
            <CISACourseDescription />
        </div>
    );
};

export default CISACourseDetails;