import React, { useContext } from "react";
import TrainingComp from "../../../Components/UserComp/TrainingComp/TrainingComp";
import { AllContext } from "../../../contextData/AllContext";

const TrainingPage = () => {
  const { courseName, setCourseName, } = useContext(AllContext);
  setCourseName("");
  return (
    <div>
      <TrainingComp />
    </div>
  );
};

export default TrainingPage;
