import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import contactLogo from "../../../assets/Images/contact/TechSimpleICT Logo-03 2 (1).png";
import Styles from "./ContactComp.module.css";
import { useFormik } from "formik";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import useTitle from "../../../hooks/useTitle";
import CommonHeader from "../../CommonComps/CommonHeader/CommonHeader";
import CommonHeader2 from "../../CommonComps/CommonHeader/CommonHeader2";

const initialValues = {
  company_name: "",
  name: "",
  phone: "",
  email: "",
  message: "",
};

const ContactComp = () => {
  useTitle("Contact");

  const validate = (values) => {
    const { name, phone, email, message } = values;
    let errors = {};
    let email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!name) {
      errors.name = "Please enter your name";
    }
    if (!phone) {
      errors.phone = "Please enter your phone";
    }
    if (!email) {
      errors.email = "Please enter your email";
    } else if (!email.match(email_regex)) {
      errors.email = "Please enter valid email";
    }
    if (!message) {
      errors.message = "Please enter your message";
    }

    return errors;
  };

  const apiUrl = process.env.REACT_APP_SERVER_ADDRESS + '/api/add-contact';

  const onSubmit = async (values) => {
    const { company_name, name, phone, email, message } = values;
    try {
      let contactData = { company_name, name, phone, email, message };

      const newContact = await Axios.post("https://techsimpleict.vercel.app/api/add-contact", contactData);
      // const newContact = await Axios.post(apiUrl, contactData);

      swal("", newContact.data?.success, "success");
      formik.values.name = "";
      formik.values.email = "";
      formik.values.phone = "";
      formik.values.message = "";
      formik.values.company_name = "";
    } catch (error) {
      swal("", "Something went wrong", "error");
    }
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  return (
    <div className={`${Styles.contactHero__style} mt-28 md:mt-36`}>
       <div className="">
        {/* <CommonHeader2
        //  shadowTitle="Contact Us"
          title="Contact" /> */}
      </div>
      <Container>
        <Toaster />
        <Row>
          <h1 className={`${Styles.mainTitle__style} text-center md:mb-10`}>
          Please share your <span className="tech">IDEA</span> or  <span className="simple">QUERIES</span> here
          </h1>
          {/* <Col md={6}>
            <div className={Styles.leftCol__style}>

              {/* <div className={Styles.titleBorder__style}></div> */}


          {/* </div> */}
          {/* </Col> */}
          <div className="">
            <div className="flex items-center md:gap-10 justify-center border-gray-400">
              <Col md={2} className=" mr-0">
                <img className=" hidden md:block img-fluid mt-3" src={contactLogo} alt="" />
              </Col>
              <Col md={6} className="">
                <div className="flex justify-center  max-w-lg ">
                  <form action="" onSubmit={formik.handleSubmit}>
                    <input
                      className={Styles.inputFiled__style}
                      type="text"
                      placeholder="Company Name (Optional)"
                      name="company_name"
                      {...formik.getFieldProps("company_name")}
                    />
                    <input
                      className={Styles.inputFiled__style}
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    <small style={{ color: "red" }}>
                      {formik.touched.name &&
                        formik.errors.name &&
                        formik.errors.name}
                    </small>
                    <input
                      className={Styles.inputFiled__style}
                      type="text"
                      placeholder="Phone Number"
                      name="phone"
                      {...formik.getFieldProps("phone")}
                    />
                    <small style={{ color: "red" }}>
                      {formik.touched.phone &&
                        formik.errors.phone &&
                        formik.errors.phone}
                    </small>
                    <input
                      className={Styles.inputFiled__style}
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      {...formik.getFieldProps("email")}
                    />
                    <small style={{ color: "red" }}>
                      {formik.touched.email &&
                        formik.errors.email &&
                        formik.errors.email}
                    </small>
                    <textarea
                      rows="4"
                      className={Styles.inputFiled__style}
                      type="text"
                      placeholder="Tell us about your need"
                      name="message"
                      {...formik.getFieldProps("message")}
                    />
                    <small style={{ color: "red" }}>
                      {formik.touched.message &&
                        formik.errors.message &&
                        formik.errors.message}
                    </small>{" "}
                    <br />
                    <div className="flex justify-center ">
                      <button className={`${Styles.submitBtn__style} flex justify-center w-full rounded text-2xl hover:bg-[#365d9b] duration-300`}>Submit</button>
                    </div>
                  </form>
                </div>
              </Col>
            </div>
            <div className="text-center mt-10 md:pl-10">
              <p style={{ margin: 0, padding: 0 }}>
                Or you can send us an email at
              </p>
              <p className={Styles.Address__Style}>techsimpleict@gmail.com</p>
              <small>(We will reply within maximum 24 hours)</small>
              <p style={{ margin: 0, padding: 0 }}>Or, call us at </p>
              <p className={Styles.Address__Style}>+880 1711 082532 <span className="text-gray-600">(WhatsApp)</span></p>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ContactComp;
