import { ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import * as React from "react";
import { HiMenu } from "react-icons/hi";
import { NavLink, useNavigate } from "react-router-dom";
import Styles from "./MobileNav.module.css";

export default function MobileNav() {
  let navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{ paddingTop: "20px" }}>
        <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/"
          >
            Home
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/aboutUs"
          >
            About
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/services"
          >
            Services
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/training"
          >
            Training
          </NavLink>
        </ListItem>
        {/* <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/go-digital"
          >
            Go Digital
          </NavLink>
        </ListItem> */}
        <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/cybersecurity"
          >
            Security-Awarness
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/blog"
          >
            Blog
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/contactUs"
          >
            Contact
          </NavLink>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <HiMenu style={{ color: "#eb3539", fontSize: 28 }} />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
