import React from "react";
import { Container } from "react-bootstrap";

const CommonHeadingTwo = ({ title, description }) => {
  return (
    <div>
      <div
        className="text-center bg-[#fbfdf] mb-3"
      >
        <Container>
          <div className="container p-3">
            <h2 className="text-3xl mb-2 font-medium">{title}</h2>
            <p className="text-base mb-2">{description}</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CommonHeadingTwo;
