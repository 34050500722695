import { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const BlogCategoryTab = ({ selectedCategory, setSelectedCategory }) => {
  const [categories, setCategories] = useState([]);
  const [categoriesData, setCategoriesData] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://tsbackend-0514.vercel.app/api/getPostCategory');
        if (response.ok) {
          const data = await response.json();

          // Extract category names from the API response
          const categoryNames = data.data.map(category => category.name);

          // Initialize categories with retrieved category names
          setCategories(['All', ...categoryNames]);

          // Transform the API response into the expected categoriesData format
          const formattedCategories = {};
          data.data.forEach((category) => {
            formattedCategories[category.name] = category.subcategories || []; 
          });

          setCategoriesData(formattedCategories);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []); // Run this effect only once on component mount

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(''); // Reset selected subcategory when changing category
  };

  const handleSubCategoryChange = (subcategory) => {
    setSelectedSubCategory(subcategory.id);
  };

  return (
    <div className="w-full px-2 sm:px-0 mx-auto">
      <div className="container border-b-2 rounded">
        <Tab.Group>
          <Tab.List className="grid grid-cols-3 lg:grid-cols-6 gap-x-3 gap-y-3 space-x-1 rounded-xl p-3">
            {categories.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    'w-full rounded-lg py-2.5 text-xs font-medium leading-5 p-2 lg:text-lg',
                    'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 border shadow-md',
                    selected
                      ? 'bg-[#f5faff]tech shadow'
                      : 'text-blue-900 hover:bg-black/30 hover:text-white shadow-md'
                  )
                }
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
      {selectedCategory && (
        <div className="">
          <div className="grid grid-cols-3 lg:grid-cols-6 gap-2">
            {categoriesData[selectedCategory]?.map((subcategory, index) => (
              <div
              key={subcategory.id || index} // Use unique ID if available, otherwise use index as key
              className={`py-2 px-4 cursor-pointer rounded mt-3 text-sm md:text-base transition-all duration-300 border-b-2 ${
                selectedSubCategory === subcategory.id
                  ? 'text-black shadow rounded-lg border-gray-500'
                  : 'text-blue-900 hover:bg-black/30 hover:text-white border-transparent'
              }`}
              onClick={() => handleSubCategoryChange(subcategory)}
            >
              {subcategory.title}
            </div>
            ))}
          </div>
          {/* Render CustomTabPanel components here for each subcategory */}
        </div>
      )}
    </div>
  );
};

export default BlogCategoryTab;
