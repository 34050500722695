import React from "react";
import AboutComp from "../../../Components/UserComp/AboutComp/AboutComp";


const AboutPage = () => {
  return (
    <div>
      <AboutComp />
    </div>
  );
};

export default AboutPage;
