import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeader from "../../../CommonComps/CommonHeader/CommonHeader";
import Styles from "./TechExpertise.module.css";
import { MdDeveloperMode, MdModelTraining, MdSecurity } from "react-icons/md";
import { SiSpringsecurity } from "react-icons/si";
import { FaHandsHelping, FaProjectDiagram } from "react-icons/fa";
// import { Security } from "@mui/icons-material";
import { SiFuturelearn } from "react-icons/si";
import net from "../../../../assets/Images/Icons/net.png";
import Java from "../../../../assets/Images/Icons/java.png";
import PHP from "../../../../assets/Images/Icons/php.png";
import JavaScript from "../../../../assets/Images/Icons/JavaScript-logo.png";
import MongoDB from "../../../../assets/Images/Icons/mongo.png";
import Reactz from "../../../../assets/Images/Icons/react.png";
import Express from "../../../../assets/Images/Icons/express.png";
import Node from "../../../../assets/Images/Icons/nodejs.png";
// import Mongoose from "../../../../assets/Images/Icons/mongoose.png";
import MySQL from "../../../../assets/Images/Icons/mysql.png";
import JIRA from "../../../../assets/Images/Icons/jira.png";
import Trello from "../../../../assets/Images/Icons/trello.svg";
import Atlassian from "../../../../assets/Images/Icons/atlassian.png";


const techExpertData = [
  {
    img: <FaHandsHelping />,
    title: "Consultancy",
    description:
      "IT Management, Digital Transformation, Information Security, Application Development",
  },
  {
    img: <MdDeveloperMode />,
    title: "Solution Development",
    description: "Custom Solutions, ERP, CRM, Mobile Apps",
  },
  {
    img: <FaProjectDiagram />,
    title: "Project Management",
    description: "Agile, SCRUM, JIRA, SLACK, Trello",
  },
  {
    img: <MdSecurity />,
    title: "CyberSecurity Awareness",
    description: "Training, Workshops, Seminars",
  },
  {
    img: <MdModelTraining />,
    title: "Training",
    description: "Corporate Training, Skill Development, Workshops",
  },
  {
    img: <SiFuturelearn />,
    title: "Digital Marketing",
    description: "SEO, Social Media Marketing, Content Marketing",
  },
];

const techIcons = [
  { name: ".NET", img: net },
  { name: "Java", img: Java },
  { name: "PHP", img: PHP },
  { name: "JavaScript", img: JavaScript },
  { name: "MongoDB", img: MongoDB },
  { name: "React", img: Reactz },
  { name: "Express", img: Express },
  { name: "Node.js", img: Node },
  // { name: "Mongoose", img: Mongoose },
  { name: "MySQL", img:MySQL },
  { name: "JIRA", img: JIRA },
  { name: "Trello", img: Trello },
  { name: "Atlassian", img: Atlassian },
];

const TechExpertise = () => {
  return (
    <div className="py-10 mt-6">
      <Container>
        <CommonHeader
          title="Technical Expertise"
          description="Knowledge is Power and good process delivers quality product"
        />
        <Row className="pt-4 justify-center">
          {techExpertData &&
            techExpertData.map((item, index) => {
              const textColorClass = index % 2 === 0 ? Styles["text-blue"] : Styles["text-red"];
              const textColorClass2 = index % 2 === 0 ? Styles["text-red"] : Styles["text-blue"];
              return (
                <Col className="py-3 hover:skew-y-3 duration-300" key={index} md={4} sm={6} xs={12}>
                  <div className="text-center flex justify-center items-center">
                    <p className={`text-center flex justify-center ${textColorClass2} mb-4`} style={{ fontSize: '5.5rem', lineHeight: '1' }}>
                      {item.img}
                    </p>
                  </div>
                  <h4 className={`${Styles.title_style} font-bold text-lg ${textColorClass}`}>{item.title}</h4>
                  <p className={Styles.description__style}>
                    {item.description}
                  </p>
                </Col>
              );
            })}
        </Row>

        <div className="pt-10 mt-10">
          <CommonHeader
            title="Technology We Use"
            description="Cutting Edge Technologies to develop Responsive Solutions"
          />
          <Row className="flex-wrap">
            {techIcons &&
              techIcons.map((item, index) => (
                <Col
                  className="py-3 text-center d-flex align-items-center justify-content-center mt-3"
                  key={index}
                  md={1}
                  lg={1}
                  sm={3}
                  xs={3}
                >
                  <div>
                    {/* <p className="mt-2">{item.name}</p> */}
                    <img className={`${Styles.techIcon__style} hover:origin-bottom hover:rotate-12 duration-300`} src={item.img} alt={item.name} />
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default TechExpertise;



// import React from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import CommonHeader from "../../../CommonComps/CommonHeader/CommonHeader";
// import Styles from "./TechExpertise.module.css";
// import img1 from "../../../../assets/Images/TechExper/icon1.png";
// import img2 from "../../../../assets/Images/TechExper/icon2.png";
// import img3 from "../../../../assets/Images/TechExper/icon3.png";
// import img4 from "../../../../assets/Images/TechExper/la_hands-helping.png";
// import icon1 from "../../../../assets/Images/Icons/Untitled-1.png";
// import icon2 from "../../../../assets/Images/Icons/2.png";
// import icon3 from "../../../../assets/Images/Icons/3.png";
// import icon4 from "../../../../assets/Images/Icons/4.png";
// import icon5 from "../../../../assets/Images/Icons/5.png";
// import icon6 from "../../../../assets/Images/Icons/6.png";
// import icon7 from "../../../../assets/Images/Icons/7.png";
// import icon8 from "../../../../assets/Images/Icons/8.png";
// import icon9 from "../../../../assets/Images/Icons/9.png";
// import icon10 from "../../../../assets/Images/Icons/10.png";
// import icon11 from "../../../../assets/Images/Icons/11.png";
// import icon12 from "../../../../assets/Images/Icons/12.png";
// import icon13 from "../../../../assets/Images/Icons/13.png";
// import icon14 from "../../../../assets/Images/Icons/14.png";
// import icon15 from "../../../../assets/Images/Icons/15.png";
// import { MdDeveloperMode, MdModelTraining, MdSecurity } from "react-icons/md";
// import { SiSpringsecurity } from "react-icons/si";
// import { FaHandsHelping, FaProjectDiagram } from "react-icons/fa";
// import { Security } from "@mui/icons-material";
// import { SiFuturelearn } from "react-icons/si";

// const techExpertData = [
//   {
//     img: <FaHandsHelping />,
//     title: "Consultancy",
//     description:
//       "IT Management, Digital Transformation, Information Security, Application Development",
//   },
//   {
//     img: <MdDeveloperMode />,
//     title: "Solution Development",
//     description: "Custom Solutions, ERP, CRM, Mobile Apps",
//   },
//   {
//     img: <FaProjectDiagram />,
//     title: "Project Management",
//     description: "Agile, SCRUM, JIRA, SLACK, Trello",
//   },
//   {
//     img: <MdSecurity />,
//     title: "CyberSecurity Awareness",
//     description: "Training, Workshops, Seminars",
//   },
//   {
//     img: <MdModelTraining />,
//     title: "Training",
//     description: "Corporate Training, Skill Development, Workshops",
//   },
//   {
//     img: <SiFuturelearn />,
//     title: "Digital Marketing",
//     description: "SEO, Social Media Marketing, Content Marketing",
//   },
// ];

// const techIcons = [
//   {
//     img: icon1,
//   },
//   {
//     img: icon2,
//   },
//   {
//     img: icon3,
//   },
//   {
//     img: icon4,
//   },
//   {
//     img: icon5,
//   },
//   {
//     img: icon6,
//   },
//   {
//     img: icon7,
//   },
//   {
//     img: icon8,
//   },
//   {
//     img: icon9,
//   },
//   {
//     img: icon10,
//   },
//   {
//     img: icon12,
//   },
//   {
//     img: icon15,
//   },
// ];

// const TechExpertise = () => {
//   return (
//     <div className="py-10 mt-6">
//       <Container>
//         <CommonHeader
//           title="Technical Expertise"
//           description="Knowledge is Power and good process delivers quality product"
//         />
//         {/* <Row className="pt-4 flex flex-row justify-center">
//           {techExpertData &&
//             techExpertData.map((item, index) => {
//               const textColorClass = index % 2 === 0 ? Styles["text-blue"] : Styles["text-red"];
//               return (
//                 <Col className="py-3 hover:skew-y-3 duration-300 " key={index} md={3} lg={3} xs={6}>
//                   <div className="text-center flex justify-center items-center">
//                     <p className={`text-center flex justify-center ${textColorClass} mb-4`} style={{ fontSize: '5.5rem', lineHeight: '1' }}>
//                       {item.img}
//                     </p>
//                   </div>
//                   <h4 className={`${Styles.title_style} font-bold text-lg`}>{item.title}</h4>
//                   <p className={Styles.description__style}>
//                     {item.description}
//                   </p>
//                 </Col>
//               );
//             })}
//         </Row> */}
//         <Row className="pt-4 " md={3} lg={3} xs={6}>
//           {techExpertData &&
//             techExpertData.map((item, index) => {
//               const textColorClass = index % 2 === 0 ? Styles["text-blue"] : Styles["text-red"];
//               return (
//                 <Col className="py-3 hover:skew-y-3 duration-300 " key={index} >
//                   <div className="text-center flex justify-center items-center">
//                     <p className={`text-center flex justify-center ${textColorClass} mb-4`} style={{ fontSize: '5.5rem', lineHeight: '1' }}>
//                       {item.img}
//                     </p>
//                   </div>
//                   <h4 className={`${Styles.title_style} font-bold text-lg`}>{item.title}</h4>
//                   <p className={Styles.description__style}>
//                     {item.description}
//                   </p>
//                 </Col>
//               );
//             })}
//         </Row>

//         <div className="pt-10 mt-10">
//           <CommonHeader
//             title="Technology We Use "
//             description="We use cutting-edge/latest technology to develop responsive solutions for web and mobile platforms within a justified budget."
//           />
//           <Row>
//             {techIcons &&
//               techIcons.map((item, index) => (
//                 <Col
//                   className="py-3 text-center d-flex align-items-center justify-content-center mt-3"
//                   key={index}
//                   md={1}
//                   lg={1}
//                   sm={3}
//                   xs={3}
//                 >
//                   <img className={`${Styles.techIcon__style} hover:origin-bottom hover:rotate-12 duration-300`} src={item.img} alt="" />
//                 </Col>
//               ))}
//           </Row>
//         </div>
//         {/* <div className="pt-10 mt-12">
//           <CommonHeader
//             title="Technology We Use ➔"
//             description="We use cutting-edge/latest technology to develop responsive solutions for web and mobile platforms within a justified budget."
//           />
//           <Row>
//             {techIcons &&
//               techIcons.map((item, index) => (
//                 <Col
//                   className="py-3 text-center d-flex align-items-center justify-content-center mt-3"
//                   key={index}
//                   md={2}
//                   lg={2}
//                   sm={3}
//                   xs={3}
//                 >
//                   <div className="text-center">
//                     <img className={`${Styles.techIcon__style} hover:origin-bottom hover:rotate-12 duration-300`} src={item.img} alt={item.name} />
//                     <p className="mt-2">{item.name} ➔</p>
//                   </div>
//                 </Col>
//               ))}
//           </Row>
//         </div> */}
//       </Container>
//     </div>
//   );
// };

// export default TechExpertise;
