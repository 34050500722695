import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Styles from "./ServiceCard.module.css";
import Tilt from 'react-parallax-tilt';

const ServiceCard = ({ item, index }) => {
  const { title, description, img } = item;

  // State to track viewport width
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  // Initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({ duration: 1000 });
    // Event listener to update viewport width state
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the text color based on index (even or odd)
  const textColor = index % 2 === 0 ? Styles["text-red"] : Styles["text-blue"];
  const textColor2 = index % 2 !== 0 ? Styles["text-red"] : Styles["text-blue2"];

  return (
    <div>
      {/* Render Tilt effect only on large screens */}
      {isLargeScreen && (
        <Tilt
          className="background-stripes parallax-effect-glare-scale"
          perspective={900}
          glareEnable={true}
          glareMaxOpacity={0.45}
          scale={0.90}
        >
          <div
            className="p-3 shadow-md h-[300px] hover:bg-[#EBF3FA] bg-[#fff] border-2 bg-opacity-10 rounded-2xl flex items-center"
            // data-aos={animationDirection}
          >
            <div className="text-center ">
              {/* Alternate text color based on index */}
              <p className={`text-center flex justify-center ${Styles.card} ${textColor} mb-1`} style={{ fontSize: '5.5rem', lineHeight: '1' }}>{img}</p>
              <h4 className={`text-lg font-bold ${textColor2} mb-3`}>{title}</h4>
              <small className="text-sm text-black mt-3">{description}</small>
            </div>
          </div>
        </Tilt>
      )}

      {/* Render without Tilt effect on small screens */}
      {!isLargeScreen && (
        <div
          className="p-3 shadow-md h-[285px] hover:bg-[#EBF3FA] bg-[#fff] border-2 bg-opacity-10 rounded-2xl flex items-center"
          // data-aos={animationDirection}
        >
          <div className="text-center ">
            {/* Alternate text color based on index */}
            <p className={`text-center flex justify-center ${Styles.card} ${textColor} mb-4`} style={{ fontSize: '5.5rem', lineHeight: '1' }}>{img}</p>
            <h4 className="text-xl font-bold tech">{title}</h4>
            <small className="text-sm text-black">{description}</small>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceCard;
