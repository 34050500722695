import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const BlogPageDetails = () => {
  const { id } = useParams(); // Extract the ID parameter from the URL

  const [blogDetails, setBlogDetails] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`https://tsbackend-0514.vercel.app/api/getBlog/${id}`); // Assuming API endpoint for fetching a specific blog by ID
        if (response.ok) {
          const data = await response.json();
          setBlogDetails(data.data); // Set the fetched blog details
        } else {
          console.error("Failed to fetch blog details");
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogDetails();
  }, [id]); // Fetch blog details whenever the ID parameter changes

  if (!blogDetails) {
    return <div>Loading...</div>; // Placeholder for loading state
  }

  const {
    category,
    heading,
    details,
    coverPhoto,
    postBy,
    usefulLink1,
    usefulLink2,
    usefulLink3,
    userType,
  } = blogDetails;

  return (
    <div className="container mx-auto mt-28 md:mt-32">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">{heading}</h1>
        <div className="text-gray-600 mb-2">{category}</div>
        <p className="text-gray-600 mb-4 text-sm fob">Blog By: <span className="font-bold">{userType}</span></p>
        <img
          src={coverPhoto}
          alt={heading}
          className="mb-4 rounded-lg shadow-md"
        />

        {/* Render blog details HTML (ensure to use dangerouslySetInnerHTML to render HTML content) */}
        <div
          dangerouslySetInnerHTML={{ __html: details }}
          className="text-gray-800 leading-relaxed mb-8"
        />

        {/* Render useful links if available */}
        <div className="flex gap-4 mb-3">
          {usefulLink1 || usefulLink2 || usefulLink3 ? (
            <div>
              <h2 className="text-lg font-semibold mb-2">Useful Links:</h2>
              <ul className="list-disc list-inside text-sm">
                {usefulLink1 && (
                  <li>
                    <a
                      href={usefulLink1}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {usefulLink1}
                    </a>
                  </li>
                )}
                {usefulLink2 && (
                  <li>
                    <a
                      href={usefulLink2}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {usefulLink2}
                    </a>
                  </li>
                )}
                {usefulLink3 && (
                  <li>
                    <a
                      href={usefulLink3}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {usefulLink3}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          ) : null}
        </div>
        {/* Back button using Link */}
        <Link
          to="/blog"
          className="text-blue-500 hover:underline  mt-4 flex justify-end mb-3"
        >
          <p className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Back
          </p>
        </Link>
      </div>
    </div>
  );
};

export default BlogPageDetails;
