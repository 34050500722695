import React from 'react';
import AwarnessOneDisplayComp from '../../../Components/UserComp/AwarnessOneComp/AwarnessOneDisplayComp';
import BlogPageComp from '../../../Components/UserComp/BlogPage/BlogPage';

const BlogPage = () => {
    return (
        <div>
           <BlogPageComp/>
        </div>
    );
};

export default BlogPage;