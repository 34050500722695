import React from "react";
import AwarnessDataEntryComp from "../../../Components/UserComp/AwarnessOneComp/AwarnessDataEntryComp";

const Awarness2023DataEntry = () => {
  return (
    <div>
      <AwarnessDataEntryComp />
    </div>
  );
};

export default Awarness2023DataEntry;
