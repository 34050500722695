import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import footerLogo from "../../../assets/Images/Logos/tsc_footer.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import Styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="border-t-2 z-10 bg-gray-50 mt-16">
        <footer className=" flex flex-col space-y-3 justify-center mt-3 mb-3">
          <div className="flex justify-center">
            <img className='w-60' src={footerLogo} alt="" />
          </div>
          <p className="text-center">
            We provide advanced, secure and scalable web solutions to ensure
            proper utilization of resources in the business.
          </p>
          <nav className="flex justify-center flex-wrap gap-3 text-gray-500 font-medium underline">
            <Link className="hover:text-blue-500" to="/">
              Home
            </Link>
            <Link className="hover:text-blue-500" to="/aboutUs">
              About
            </Link>
            <Link className="hover:text-blue-500" to="/services">
              Services
            </Link>
            <Link className="hover:text-blue-500" to="/cybersecurity">
              Cyber Security
            </Link>
            {/* <Link className="hover:text-blue-500" to="/awarness2023">
              Cyber Awaness
            </Link> */}
            <Link className="hover:text-blue-500" to="/training">
              Training
            </Link>
            <Link className="hover:text-blue-500" to="/contactUs">
              Contact
            </Link>
          </nav>

          <div className="flex justify-center space-x-5">
            <a href="https://www.facebook.com/techsimpleict" target="_blank" rel="noopener noreferrer">
              <img src="https://img.icons8.com/fluent/30/000000/facebook-new.png" alt="Facebook" />
            </a>
            <a href="https://www.linkedin.com/company/techsimpleict/" target="_blank" rel="noopener noreferrer">
              <img src="https://img.icons8.com/fluent/30/000000/linkedin-2.png" alt="LinkedIn" />
            </a>
            {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png" alt="Instagram" />
            </a>
            <a href="https://messenger.com" target="_blank" rel="noopener noreferrer">
              <img src="https://img.icons8.com/fluent/30/000000/facebook-messenger--v2.png" alt="Messenger" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src="https://img.icons8.com/fluent/30/000000/twitter.png" alt="Twitter" />
            </a> */}
          </div>
          <p className="text-center text-gray-700 font-medium">&copy; {new Date().getFullYear()} TechSimpleICT – ALL RIGHTS RESERVED</p>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
