import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeader from "../../CommonComps/CommonHeader/CommonHeader";

import sumImg from "../../../assets/Images/summary/TechSimpleICT_Logo-03_2__1_.9d7084dc3d0f494aeaf3-removebg-preview.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import useTitle from "../../../hooks/useTitle";
import ServicesHeader from "../ServicesComp/ServicesHeader/ServicesHeader";
import UserStrongPassword from "./UserStrongPassword/UserStrongPassword";
import UsePasswordManager from "./UsePasswordManager/UsePasswordManager";
import MultiFactorAuthentication from "./MultiFactorAuthentication/MultiFactorAuthentication";
import RecognizeRandReportPhishing from "./RecognizeAndReportPhishing/RecognizeRandReportPhishing";
import SoftwareUpdates from "./SoftwareUpdates/SoftwareUpdates";
import Styles from "../CybersecurityComp/ServersecurityComp.module.css";
import CyberSecuritySubHeading from "../../CommonComps/CommonHeadingTwo/CyberSecuritySubHeading";
import AwarenssCampain from "./AwarenssCampain/AwarenssCampain";
import SecurityServicesHeader from "../ServicesComp/ServicesHeader/SecurityServicesHeader";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import './CyberSecurityAwarness.css';
import { GoTasklist } from "react-icons/go";
import { MdLibraryBooks } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import ServiceSection from "./PopularServices/ServiceSection ";
import WhyChooseTechSimpleICT from "./WhyChooseTechSimpleICT/WhyChooseTechSimpleICT";
import tsc from '../../../assets/Images/Logos/tcs_full_logo.38c7ebcab66301011386-removebg-preview.png'
import tsLogo from "../../../assets/Images/Logos/ts-logo-2023-full-center-on-white.png";
import tsLogo2 from "../../../assets/Images/Logos/ts-logo-2023-full-horizontal-on-white.png";
import tscLogo from "../../../assets/Images/Logos/tsc_footer.png";
import CommonHeader2 from "../../CommonComps/CommonHeader/CommonHeader2";




const cybersecurityData = [
  {
    id: 1,
    icon: <GoTasklist />,
    title: "Measurement",
    description:
      "“If you can not measure it, you can not improve it”. - Lord Kelvin",
  },
  {
    id: 2,
    icon: <MdLibraryBooks />,
    title: "Planning",
    description:
      "“A good plan is like a road map: it shows the final destination and usually the best way to get there.“ – H. Stanely Judd ",
  },
  {
    id: 3,
    icon: <IoSettingsOutline />,
    title: "Implementation",
    description:
      "“It's important to have a sound idea, but the really important thing is the implementation.“ - Wilbur Ross ",
  },


];


const CybersecurityComp = () => {
  useTitle("SecurityAwareness");
  const [activeBtn, setActiveBtn] = useState(cybersecurityData[0].id);
  const [headerInfo, setHeaderInfo] = useState(cybersecurityData[0]);

  const navigate = useNavigate();
  return (
    <div className=" " style={{ marginBottom: "2rem" }}>


      <div className="mt-44">
        <CommonHeader2
        //  shadowTitle="Security Awareness" 
         title="Cybersecurity Awareness" 
         description='According to Cybint, 95% of cybersecurity breaches are caused by human error, meaning they were likely preventable. 
' />
      </div>




      {/* TechSimple ICT Service on Cybersecurity Awareness Section Start */}
      <div className={`${Styles.summary3__style} relative flex justify-center items-center mt-3`}>
        {/* Image floated on the left */}
        {/* <img className="absolute left-20 top-40 w-[30px] h-64 " src={sumImg} alt="" /> */}

        <div className="container mx-auto pt-1 pb-3 text-center ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-4">
            <div className="pt-3">

              <h2 className="text-center text-black text-xl lg:text-3xl font-bold mt-1">
                CyberSecurity Awareness Services
              </h2>
              <div className="flex flex-col items-center justify-center">
                <div className=" flex items-center justify-center rounded-lg mb-2 bg-white mt-4 ">
                  <Link to="https://techsimpleict.com/">
                    <img className="fw-bold w-72 h-20 p-3  shadow-lg" src={tscLogo} alt=""></img>
                  </Link>
                </div>
              </div>
              <p className="text-center text-black lg:text-lg lg:leading-relaxed mt-3">
                <span className="font-bold">Awareness Programs and Campaign</span>  will help you to
                improve <span className="font-bold">Security Culture</span> within organization.
              </p>
            </div>

            <div className="flex flex-col items-center justify-center">
              <h2 className="text-black text-center mb-2 text-xl lg:text-3xl font-bold">
                CyberSecurity Awareness Partner
              </h2>
              {/* <div className="w-48 h-32 bg-white flex items-center justify-center rounded-lg mb-2 mt-2">
                Placeholder for logo
                <a href="https://www.treesolution.com/" target="_blank" rel="noreferrer" className="">
                  <img className="fw-bold shadow-lg" src={tsLogo} alt="TreeSolution Logo"></img>
                </a>
              </div> */}
              <div className=" bg-white flex items-center justify-center rounded-lg mb-2 mt-4">
                {/* Placeholder for logo */}
                <a href="https://www.treesolution.com/" target="_blank" rel="noreferrer" className="">
                  <img className="fw-bold shadow-lg w-72 h-20" src={tsLogo2} alt="TreeSolution Logo"></img>
                </a>
              </div>
              <p className="text-center text-black text-xl lg:text-2xl font-bold mt-2">
                TreeSolution Security Awareness AG
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* TechSimple ICT Service on Cybersecurity Awareness Section End*/}




      {/* awarness Capmain section start */}
      {/* <div className="awarnessCapmainArea py-5">
        <div className="text-3xl">
          <h1 className=" text-center mb-0">Join with
            <span className="textTech ms-2">Tech</span>
            <span className="textSimple pe-2">Simple</span>
            <span className=" textICT">ICT</span>
          </h1>
          <h1 className=" text-center mt-0 "> <span className="">Cybersecurity Awareness</span> Activities</h1>
        </div>

        <div className=" mt-5 mb-1">
          <AwarenssCampain></AwarenssCampain>
        </div>
      </div> */}
      {/* awarness Capmain section end*/}



      {/* bottom button card section start */}
      <div className="container">
        <div className="col-lg-12 mx-auto">
          <div className="">
            <ServiceSection />
          </div>
          <div className="mt-2 flex justify-center"> <p className="border-b-2 border-b-[#EA2126] inline text-3xl">  Our Process:</p></div>
          <Container className="pt-3">
            {/* SERVICES FILTERING */}

            <div className="hidden md:block">
              <Row>
                {cybersecurityData &&
                  cybersecurityData?.map((item, index) => {
                    return (
                      <Col className="py-2" key={index} md={4}>
                        <button
                          onClick={() => {
                            setActiveBtn(item.id);
                            setHeaderInfo(item);
                          }}
                          className={
                            activeBtn == index + 1
                              ? `${Styles.activeBtn__style} px-5 py-4 items-center flex justify-center text-3xl border-b-2 border-b-[#EA2126]`
                              : `${Styles.normalBtn__style} px-5 py-4 items-center flex justify-center  text-3xl rounded hover:border-b-2 border-b-[#EA2126]`
                          }
                        >
                          <p className="flex items-center gap-3 text-5xl">{item.icon} <span className="text-2xl">{item.title}</span></p>
                        </button>
                      </Col>
                    );
                  })}
              </Row>
            </div>
            <div className="md:hidden">
              <Row>
                {cybersecurityData &&
                  cybersecurityData.map((item, index) => {
                    return (
                      <Col className="py-2" key={index} md={4}>
                        <button
                          onClick={() => {
                            setActiveBtn(item.id);
                            setHeaderInfo(item);
                          }}
                          className={
                            activeBtn === item.id
                              ? `${Styles.activeBtn__style} px-5 py-4 items-center flex justify-center text-3xl border-b-2 border-b-[#EA2126]`
                              : `${Styles.normalBtn__style} px-5 py-4 items-center flex justify-center text-3xl rounded hover:border-b-2 border-b-[#EA2126]`
                          }
                        >
                          <p className="flex items-center gap-3 text-5xl">
                            {item.icon}
                            <span className="text-2xl">{item.title}</span>
                          </p>
                        </button>
                        {activeBtn === item.id && (
                          <div className="p-4">
                            <h3 className="text-xl font-bold">{headerInfo.title}</h3>
                            <p className="text-lg">{headerInfo.description}</p>
                            {headerInfo.title.toLowerCase() === "measurement" && (
                              <div>
                                <p className="font-bold text-xl mb-2">
                                  Objective:
                                  <span className="text-base font-normal">
                                    {" "}
                                    Measure CURRENT STATUS of CyberSecurity Culture to plan further.
                                  </span>
                                </p>
                                <p className="text-lg mb-2">
                                  <span className="font-bold text-xl mb-2">Procedure:</span>
                                  Our specialized assessment tools will be used to capture the realistic picture of your organization. We try to relate findings with risk factors and possible impact.
                                </p>
                                <ul className="list-disc pl-6 mb-4">
                                  <li className="text-base">Gain knowledge about existing CyberSecurity Culture.</li>
                                  <li className="text-base">Define maturity of existing culture.</li>
                                  <li className="text-base">Identify gaps with existing market good practice.</li>
                                  <li className="text-base">Identify gaps with planned improvement</li>
                                </ul>
                              </div>
                            )}
                            {headerInfo.title.toLowerCase() === "planning" && (
                              <div>
                                <p className="font-bold text-xl mb-2">
                                  Objective:
                                  <span className="text-base font-normal">
                                    {" "}
                                    Way out and goal setting according to the business objective to minimize the gap and improve cybersecurity practice.
                                  </span>
                                </p>
                                <p className="text-lg mb-2">
                                  <span className="font-bold text-xl">Procedure:</span>
                                </p>
                                <ul className="list-disc pl-6 mb-4">
                                  <li className="text-base">Develop CyberSecurity Awareness Strategy</li>
                                  <li className="text-base">Define Campaign Goal and develop Content</li>
                                  <li className="text-base">Set Long Term and Short Term Security Strategy</li>
                                  <li className="text-base">Budget and Resource Planning to execute</li>
                                </ul>
                              </div>
                            )}
                            {headerInfo.title.toLowerCase() === "implementation" && (
                              <div>
                                <p className="font-bold text-xl mb-2">
                                  Objective:
                                  <span className="text-base font-normal">
                                    {" "}
                                    Implement a Smart and Sustainable Cybersecurity practice within your organization.
                                  </span>
                                </p>
                                <p className="text-lg mb-2">
                                  <span className="font-bold text-xl mb-2">Procedure:</span>
                                  Our specialized assessment tools will be used to capture the realistic picture of your organization. We try to relate findings with risk factors and possible impact.
                                </p>
                                <ul className="list-disc pl-6 mb-4">
                                  <li className="text-base">Raise Awareness among target audience using various methods</li>
                                  <li className="text-base">Classroom/hallroom based session</li>
                                  <li className="text-base">Identify gaps with existing market good practice</li>
                                  <li className="text-base">Campaign using social media and intranet media</li>
                                  <li className="text-base">Environment readiness to embed culture within organization</li>
                                  <li className="text-base">Awareness with Fun and Play</li>
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </Container>


          <div className="shadow-md rounded-lg hidden md:block">
            <SecurityServicesHeader
              title={headerInfo.title}
              description={headerInfo.description}
            />

            <div className=" container mb-5 ">

              {headerInfo &&
                headerInfo?.title.toLowerCase().replaceAll(" ", "") ===
                "Measurement".toLowerCase().replaceAll(" ", "") && (
                  <div className="max-w-3xl mx-auto p-4">
                    <p className="font-bold text-xl mb-2">Objective :<span className="text-base font-normal"> Measure CURRENT STATUS of CyberSecurity Culture to plan further.  </span></p>
                    <p className="text-lg mb-2 ">
                      <p className="font-bold text-xl mb-2">Procedure:</p>
                      Our specialized assessment tools will be used to capture the realistic picture of your organization. We try to relate findings with risk factors and possible impact.
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                      <li className="text-base">Gain knowledge about  existing CyberSecurity Culture. </li>
                      <li className="text-base">Define maturity of existing culture.</li>
                      <li className="text-base">Identify gaps with existing market good practice.</li>
                      <li className="text-base">Identify gaps with planned improvement </li>
                    </ul>
                    {/* <p className="text-lg">
                      Our specialized assessment tools will be used to capture the realistic picture of your organization. We share the report with you for vetting.
                    </p> */}
                  </div>
                )}


              {headerInfo &&
                headerInfo?.title.toLowerCase().replaceAll(" ", "") ===
                "Planning".toLowerCase().replaceAll(" ", "") && (
                  <div className="max-w-3xl mx-auto p-4">
                    <p className="font-bold text-xl mb-2">Objective :<span className="text-base font-normal"> Way out and goal setting according the business objective to minimize the gap and improve cybersecurity practice   </span></p>
                    <p className="text-lg mb-2">
                      <p className="font-bold text-xl">Procedure:</p>
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                      <li className="text-base">Develop CyberSecurity Awareness Strategy </li>
                      <li className="text-base">Define Campaign Goal and develop Content</li>
                      <li className="text-base">Set Long Term and Short Term Security Strategy</li>
                      <li className="text-base">Budget and Resource Planning to execute</li>
                    </ul>
                    {/* <p className="text-lg">
                      Our specialized assessment tools will be used to capture the realistic picture of your organization. We share the report with you for vetting.
                    </p> */}
                  </div>
                )}


              {headerInfo &&
                headerInfo?.title.toLowerCase().replaceAll(" ", "") ===
                "Implementation".toLowerCase().replaceAll(" ", "") && (
                  <div className="max-w-3xl mx-auto p-4">
                    <p className="font-bold text-xl mb-2">Objective :<span className="text-base font-normal"> Implement a Smart and Sustainable Cybersecurity practice within your organization.  </span></p>
                    <p className="text-lg mb-2 ">
                      <p className="font-bold text-xl mb-2">Procedure:</p>
                      Our specialized assessment tools will be used to capture the realistic picture of your organization. We try to relate findings with risk factors and possible impact.
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                      <li className="text-base">Raise Awareness among target audience using various methods</li>
                      <li className="text-base">Classroom/hallroom based session </li>
                      <li className="text-base">Identify gaps with existing market good practiceVirtual Training</li>
                      <li className="text-base">Campaign using social media and intranet media </li>
                      <li className="text-base">Environment readiness to embed culture within organization </li>
                      <li className="text-base">Awareness with Fun and Play</li>
                    </ul>
                    {/* <p className="text-lg">
                    Our specialized assessment tools will be used to capture the realistic picture of your organization. We share the report with you for vetting.
                  </p> */}
                  </div>
                )}





            </div>
          </div>


          {/* CyberSecurityAwarness SubHeading Section start */}
          <div className="my-5">
            <WhyChooseTechSimpleICT />
          </div>

          <CyberSecuritySubHeading
            title="Cybersecurity Awareness Month – October "
            description1="Worldwide October is observed as Cybersecurity Awareness Month and in this month governments and private organizations take initiative to raise awareness about digital security and empower everyone to protect their personal data from digital form of crime."
            description2="October 2023 marks as 20 years of cybersecurity awareness month as firstly this event started at October, 2004 in the USA."
            description3="Cybersecurity Awareness Month October, 2023 will focus on 4 key behaviors all month long:"
          />

          {/* CyberSecurityAwarness SubHeading Section end */}
        </div>
      </div>
      {/* bottom button card section end*/}



    </div>
  );
};

export default CybersecurityComp;
