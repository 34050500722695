import React from 'react';
import Trainer from '../../../../assets/Images/traniner/traniner.jpg'
import './CRISCCourseDescription.css';

const AboutCRISCTrainer = () => {
    return (
        <div className='mt-4'>
            <h2 className='courseDetailsBanner py-3 text-center mt-5 mb-4 fw-bold text-3xl font-bold'>About Trainer</h2>
            <div className='col-lg-10 mx-auto'>
                <div className=' d-md-flex justify-content-center align-items-center'>
                    <div className='flex justify-center col-md-4 text-md-start text-center'>
                        <img className='trainerImg img-fluid shadow-lg' src={Trainer} alt="trainerImg" />
                    </div>
                    <div className=' col-md-8 text-md-start text-center mt-md-0 mt-3'>
                        <p> <b> Md. Shamsuzzaman Sarker</b> <br />
                            CISA, CRISC, ISO 27001 LA & LI, APMG Accredited Trainer on CISA and CRISC <br />
                            01711082532, techsimpleict@gmail.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCRISCTrainer;