import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeader from "../../../CommonComps/CommonHeader/CommonHeader";
import ServiceCard from "./ServiceCard/ServiceCard";
import Styles from "./Services.module.css";
import img1 from "../../../../assets/Images/services/carbon_application-web.png";
import img2 from "../../../../assets/Images/services/Vector (1).png";
import img3 from "../../../../assets/Images/services/icon2.png";
import img4 from "../../../../assets/Images/services/carbon_task-asset-view.png";
import img5 from "../../../../assets/Images/services/healthicons_i-training-class-outline.png";
import img6 from "../../../../assets/Images/services/carbon_help-desk.png";
import useTitle from "../../../../hooks/useTitle";
import { AiOutlineSolution } from "react-icons/ai";
import { FaChalkboardTeacher, FaHandsHelping } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";
import { IoMdClipboard } from "react-icons/io";
import { SiFuturelearn } from "react-icons/si";


const serviceData = [
  {
    img: <AiOutlineSolution />,
    title: "Solution Development",
    description:
      "We use cutting edge/latest technology to development responsive solutions for web and mobile platform within justified budget.",
  },
  {
    img: <FaHandsHelping />,
    title: "Consultancy",
    description:
      "We have certified and experienced Consultant Team who can help to assess business processes and IT investment and help you to align them with business objective.",
  },
  {
    img: <MdOutlineSecurity />
   ,
    title: "Information Security ",
    description:
      "Information is very important asset for your business and that should be secured properly. TechSimple ICT can help you to protect business Information.",
  },
  {
    img: <IoMdClipboard />
    ,
    title: "Assesment & Assurance",
    description:
      "Regardless of what your business, TechSimple ICT will help you to identify risks and set direction to secure IT environment to fulfill business objective. ",
  },
  {
    img: <FaChalkboardTeacher />
    ,
    title: "Training",
    description:
      "Training is required both for individual and organization to ensure continuous growth and improvement.",
  },
  {
    img: <SiFuturelearn />
    ,
    title: "Go Digital",
    description:
      "TechSimple ICT helps businesses by taking responsibility for IT Operation, maintenance and delivery for the business.",
  },
];

const Services = () => {
  return (
    <div className="container" style={{ marginTop: "80px" }}>
      
      <Container className="py-2 mt-5">
        <CommonHeader
          // shadowTitle="Services"
          title="Our Services"
          description="We serve the right solution to build better digital world"
        />
        <Row>
          {serviceData &&
            serviceData?.map((item, index) => {
              return (
                <Col className="py-3 rounded hover:scale-105 duration-300 transition-transform" key={index} md={6} sm={6} lg={4}>
                  <ServiceCard item={item} index={index} />
                </Col>
              );
            })}
        </Row>
      </Container>
    </div>
  );
};

export default Services;
