import React from 'react';
import CourseDetailsBanner from './CRISCCourseDetailsBanner';
import CRISCCourseDescription from './CRISCCourseDescription';
import useTitle from '../../../../hooks/useTitle';

const CRISCCourseDetails = () => {
    useTitle("CourseDetails")
    return (
        <div className=''>
            <CourseDetailsBanner />
            <CRISCCourseDescription />
        </div>
    );
};

export default CRISCCourseDetails;