import React from 'react';
import NavbarComp from '../Components/UserComp/NavbarComp/NavbarComp';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Components/UserComp/Footer/Footer';
import ScrollToTop from '../hooks/ScrollToTop';
import '../hooks/CustomScollBar.css'
import CircleTrail from '../Components/CircleTracker/CircleTrail';

const MainLayout = () => {
    const location = useLocation();

    return (
        <div className=' min-vh-100 d-flex flex-column '>
            <ScrollToTop />
            <CircleTrail key={location.pathname} />
            {/* bg-gradient-to-r from-blue-100 via-white to-purple-100 */}
            <NavbarComp />
            <div className=' flex-grow-1'>
                <Outlet></Outlet>
            </div>
            <Footer />
        </div>
    );
};

export default MainLayout;