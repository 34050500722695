import React, { useContext } from 'react';
import AboutCISACourse from './AboutCISACourse';
import CISACourseFee from './CISACourseFee';
import AboutCISATrainer from './AboutCISATrainer';
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import './CourseDescription.css';
import { AllContext } from '../../../../contextData/AllContext';

const CISACourseDescription = () => {
    const { courseName, setCourseName, } = useContext(AllContext);
    setCourseName("CISA Certification");
    const navigate = useNavigate();

    return (
        <div>
            <div className='  container'>
                <div className=' col-lg-8 mx-auto mb-2'>
                    <AboutCISACourse></AboutCISACourse>
                    <CISACourseFee></CISACourseFee>
                    <AboutCISATrainer></AboutCISATrainer>
                </div>

                <div className='flex justify-center items-center text-center mb-3'>
                    <button
                        onClick={() => navigate("/registration/:id")}
                        className="sliderBtnBottom flex items-center gap-1"
                    >
                        <span className=""> Registration</span>
                        <AiOutlineArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CISACourseDescription;