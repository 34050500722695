import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const CustomerReviewSlider = () => {
  // Static data for customer reviews
  const reviews = [
    {
      id: 1,
      name: "Dr. Ahmed Munirus Saleheen",
      designation: (
        <>
          <span className="font-bold">President</span>, Jahangirnagar University BCS Officers' Forum <br />
          and <br />
          <span className="font-bold">Chairman</span>, Bangladesh Trade and Tariff Commission
        </>
      ),
      feedback: (
        <>
          <span className="font-bold">TechSimple ICT</span> has modernized
          <span className="font-bold"> JUBOF's River Cruise-2024 Event Registration</span>, shifting from manual to
          online processes, enabling seamless participant registration,
          information updates, and fee payments through a <span className="font-bold"> user-friendly online
          platform</span>, enhancing overall <span className="font-bold">event management and communication
          efficiency</span>.
          <br />
          <br />
          The <span className="font-bold">JUBOF Online System (www.jubofbd.com)</span> is a vital initiative by
          <span className="font-bold"> TechSimple ICT</span>, our ICT Partner, enabling effective management of our
           <span className="font-bold"> SMART Forum</span> and enhancing connectivity among association members.
        </>
      ),
      rating: 5,
      image:
        "https://i.ibb.co/YB9JJXY/c5bdeca5-2431-4e11-ae8d-691a5be85f9f.jpg",
    },
    {
      id: 2,
      name: "MOHAMMAD ASHRAFUZZAMAN, BPM",
      designation: (
        <>
          <span className="font-bold">AIG</span>, PIMS Section, <br /> Police Headquarters
        </>
      ),
      feedback: (
        <>
          <span className="font-bold">TechSimple ICT</span> has developed the{" "}
          <span className="font-bold"> Online Directory</span> for the
          <span className="font-bold"> Bangladesh Police Service Association (BPSA)</span> which will <span className="font-bold italic"> eventually
          replace the annual Printed Directory Book of Bangladesh Police.</span> The
          Online Directory ensures that members have access to updated
          information at all times. The system is secure and responsive,
          allowing only organization members to access the directory, which will
          enhance our organization's overall security measures.
          <br />
          <br />I believe this initiative is a fantastic transformation for the
          <span className="font-bold"> SMART Association</span> and its members.
        </>
      ),
      rating: 5,
      image:
        "https://i.ibb.co/G2MxGk7/34c1c435-2913-4eb2-ae6b-c205fc76db39.jpg",
    },
  ];

  // Ref for slider
  const sliderRef = useRef(null);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="bg-gray-50 py-16 shadow-md border">
      <div className="container mx-auto px- md:px-12 lg:px-24">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          <div className="flex flex-col justify-center lg:col-span-1">
            {/* <h2 className="text-lg font-semibold text-blue-600">Testimonials</h2> */}
            <h1 className="text-4xl font-bold text-gray-900"> Clients Feedback about <span className="textTech text-4xl">Tech</span>
          <span className="textSimple pe-2 text-4xl">Simple</span>
          <span className="textICT text-4xl">ICT</span></h1>
            <p className="text-gray-700 mt-4">
            TechSimpleICT delivers innovative technology solutions that drive efficiency and productivity for our clients.
            </p>
          </div>
          <div className="relative lg:col-span-2">
            <Slider ref={sliderRef} {...settings}>
              {reviews.map((review) => (

                <>
                <div key={review.id} className="bg-white p-6 rounded-xl border hidden md:block">
                  <div className="flex items-center justify-center">
                    <img
                      src={review.image}
                      alt={review.name}
                      className="w-20 h-20 rounded-full shadow-md object-cover"
                    />
                    <div className="ml-4">
                      <h3 className="text-xl font-semibold">{review.name}</h3>
                      <p className="text-gray-600">{review.designation}</p>
                    </div>
                  </div>
                  <p className="text-gray-800 mt-4">{review.feedback}</p>
                  <div className="flex justify-center mt-4">
                    {Array.from({ length: review.rating }, (_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-yellow-500 fill-current"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 0l2.915 6.698L20 7.65l-5 5.432 1.183 7.037L10 15.964l-6.183 4.155L5 12.082l-5-5.432 7.085-.952L10 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ))}
                  </div>
                </div>

                <div key={review.id} className="bg-white p-6 rounded-xl border md:hidden">
                  <div className="md:flex items-center justify-center">
                    <img
                      src={review.image}
                      alt={review.name}
                      className="w-20 h-20 rounded-full shadow-md object-cover mx-auto my-3"
                    />
                    <div className="ml-4">
                      <h3 className="text-xl font-semibold text-center">{review.name}</h3>
                      <p className="text-gray-600 text-center">{review.designation}</p>
                    </div>
                  </div>
                  <p className="text-gray-800 mt-4">{review.feedback}</p>
                  <div className="flex justify-center mt-4">
                    {Array.from({ length: review.rating }, (_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-yellow-500 fill-current"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 0l2.915 6.698L20 7.65l-5 5.432 1.183 7.037L10 15.964l-6.183 4.155L5 12.082l-5-5.432 7.085-.952L10 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ))}
                  </div>
                </div>
                </>

                
              ))}
            </Slider>
            {/* <button
              className="absolute top-0 left-4 transform -translate-y-1/2 z-0 bg-[#F4E9FF] rounded-full p-2 shadow-md focus:outline-none"
              onClick={() => sliderRef.current.slickPrev()}
            >
              <BiChevronLeft className="h-6 w-6 text-gray-600" />
            </button> */}
            <button
            aria-label="BiChevronRight"
              className="absolute top-0 right-4 transform -translate-y-1/2 z-0 bg-[#F4E9FF] rounded-full p-2 shadow-md focus:outline-none"
              onClick={() => sliderRef.current.slickNext()}
            >
              <BiChevronRight className="h-6 w-6 text-gray-600" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReviewSlider;
