import React from 'react';
import { Link } from 'react-router-dom';

const AwarenssOneDetails = ({ awarness }) => {
    const { heading, details, coverPhoto, usefulLink1, _id } = awarness;

    const isSimpleEntry = () => {
        return !details;
    };

    return (
        <div className="mb-6 relative">
            <div className="mx-1 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 border">
                {/* Conditional rendering of the External Link tag */}
                {isSimpleEntry() && (
                    <div className="absolute top-0 right-0 bg-red-600 text-white text-xs uppercase px-3 py-2 rounded-bl-lg">
                        External Link
                    </div>
                )}
                <img src={coverPhoto} alt={heading} className="w-full h-48 object-cover" />
                <div className="p-4 bg-white">
                    <p className="text-lg font-semibold text-gray-800 mb-2">{heading}</p>
                    <div className="flex justify-end">
                        {isSimpleEntry() ? (
                            <a href={usefulLink1} target="_blank" rel="noopener noreferrer"
                                className="text-sm text-blue-600 hover:text-blue-800 focus:outline-none focus:ring focus:border-blue-300 px-3 py-1 rounded-md border border-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out">
                                Learn More
                            </a>
                        ) : (
                            <Link to={`/blog/${_id}`}
                                className="text-sm text-blue-600 hover:text-blue-800 focus:outline-none focus:ring focus:border-blue-300 px-3 py-1 rounded-md border border-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out">
                                Learn More
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AwarenssOneDetails;
