import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import './Registration.css'
import CRISCRegistrationTop from "./CRISCRegistrationTop";
import CISARegistrationTop from "./CISARegistrationTop";
import useTitle from "../../../hooks/useTitle";
import { AllContext } from "../../../contextData/AllContext";
import Loader from "../../../Components/CommonComps/Loader/Loader";
import { useParams } from "react-router-dom/dist";
import axios from "axios";

const RegistrationPage = () => {
    useTitle("Registration");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const { stdData, setStdData, courseName } = useContext(AllContext);
    const courseId = useParams().id;
    const [selectedTrainingType, setSelectedTrainingType] = useState('classroomBased');


    // console.log("RegistrationPage : ", stdData);
    // console.log("RegistrationPage CourseName : ", courseName);

    const navigate = useNavigate();

    const handleRegistrationpmit = async (event) => {
        // console.log('I am clicked');
        event.preventDefault();
        const form = event.target;
        setErrorMessage("");
        // const courseName = companyName;
        let courseFee;
        if (selectedTrainingType == 'classroomBased') {
            courseFee = "18000";
        }
        if (selectedTrainingType == 'onlineBased') {
            courseFee = "15000";
        }
        if (selectedTrainingType == 'oneToOne') {
            courseFee = "contact with trainer";
        }
        if (form.phone.value.length != 11) {
            // console.log("hello");
            setErrorMessage("Please enter valid mobile number")
            return;
        }


        // const trainingType = form.trainingType.value;
        const name = form.name.value;
        const phone = form.phone.value;
        const email = form.email.value;
        const presentAddress = form.presentAddress.value;
        const companyName = form.companyName.value;
        const comments = form.comments.value;


        const regData = {
            courseName: courseId + " Certification",
            trainingType: selectedTrainingType,
            courseFee: courseFee,
            name: name,
            phone: phone,
            email: email,
            presentAddress: presentAddress,
            companyName: companyName,
            comments: comments,
            courseId: courseId,
        }

        console.log(" Registration page Data : ", regData);

        // navigate('/regDetails/:id')
        // const apiUrl = process.env.REACT_APP_SERVER_ADDRESS + '/api/add-registration';
        const apiUrl = `${process.env.REACT_APP_SERVER_ADDRESS}/api/add-registration`;

        // fetch(`https://techsimpleict.vercel.app/api/add-registration`, {
        setLoading(true)
        await fetch(apiUrl, {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(regData),
        })
            .then(response => response.json())
            .then(async (data) => {
                // console.log('Registration Data : ', data);
                if (data.statusCode = 201) {
                    const result = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/sendSms/${regData.phone}`);
                    console.log(result)
                    form.reset()
                    toast.success('Congratulation! Your Registration Successful.');
                    setStdData(regData);
                    setLoading(false);
                    navigate(`/regDetails/${regData.courseId}`);
                    return;
                }
                toast.error(' Your Registration has not pmitted.');
                // setStdData(regData);
                setLoading(false);
            })
            .catch(error => {
                // console.log("Error Occured: ", error.response.data)
            })
    }

    if (loading) {
        return <Loader></Loader>
    }


    return (
        <div className="container mt-28">
            <div className="  mb-2 ">
                <div className=" col-lg-10  mx-auto">
                    {courseName == "CRISC Certification" &&
                        <CRISCRegistrationTop></CRISCRegistrationTop>
                    }
                    {courseName == "CISA Certification" &&
                        <CISARegistrationTop></CISARegistrationTop>
                    }

                    <h2 className=' courseDetailsBanner py-3 text-center mt-5 mb-2 fw-bold text-3xl font-bold border shadow-sm rounded-md'>Registration Form</h2>
                    <div className="regForm col-lg-6 col-md-9 mx-auto  p-md-4 p-2 border shadow-sm rounded-md" >
                        <h3 className="text-center text-danger">{errorMessage}</h3>
                        <form onpmit={handleRegistrationpmit}>
                            <div className="mb-3">
                                <div className="d-flex flex-row">
                                    <label htmlFor="name" className="form-label fw-bold mb-0 me-0">Name</label><p className="star ms-0">*</p>
                                </div>
                                <input type="text" name="name" className="form-control" id="name" placeholder="Enter full name" required />
                            </div>

                            <div className=" col-12 d-md-flex">
                                <div className="mb-3 col-md-6 me-1">
                                    <div className="d-flex flex-row">
                                        <label htmlFor="phone" className="form-label fw-bold mb-0 me-0">Phone</label><p className="star ms-0">*</p>
                                    </div>
                                    <input type="text" name="phone" className="form-control" id="phone" placeholder="Enter phone number" required />
                                </div>

                                <div className="mb-3 col-md-6">
                                    <div className="d-flex flex-row">
                                        <label htmlFor="email" className="form-label fw-bold mb-0 me-0">Email Address</label><p className="star ms-0">*</p>
                                    </div>
                                    <input type="email" name="email" className="form-control" id="email" placeholder="Enter email" required />
                                    {/* <div id="emailHelp" className="form-text">Well never share your email with anyone else.</div> */}
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex flex-row">
                                    <label htmlFor="trainingType" className="form-label fw-bold mb-0 me-0">Select Training Type</label><p className="star ms-0">*</p>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="oneToOne" defaultChecked onChange={() => setSelectedTrainingType('oneToOne')} />
                                    <label className="form-check-label" htmlFor="oneToOne">
                                        One To One
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="onlineBased" onChange={() => setSelectedTrainingType('onlineBased')} />
                                    <label className="form-check-label" htmlFor="onlineBased">
                                        Online Based
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="classroomBased"  onChange={() => setSelectedTrainingType('classroomBased')} />
                                    <label className="form-check-label" htmlFor="classroomBased">
                                        Classroom Based
                                    </label>
                                </div>
                            </div>

                            <div className="mb-3 ">
                                <div className="d-flex flex-row">
                                    <label htmlFor="presentAddress" className="form-label fw-bold mb-0 me-0">Present address</label><p className="star ms-0">*</p>
                                </div>
                                <input type="text" name="presentAddress" className="form-control" id="presentAddress" placeholder="Enter present address" required />
                            </div>

                            <div className="mb-3 ">
                                <label htmlFor="companyName" className="form-label fw-bold mb-0">Company Name</label>
                                <input type="text" name="companyName" className="form-control" id="companyName" placeholder="Enter company name" />
                            </div>


                            <div className=" mb-3">
                                <label htmlFor="comments" className="form-label fw-bold mb-0">Comments</label>
                                <textarea className="form-control" name="comments" placeholder="Leave a comment here" id="floatingTextarea" maxLength={200} />
                            </div>

                            <div className=" d-flex justify-content-between">
                                <button type="reset" className="btn btn-warning ">Reset</button>
                                <button type="pmit" className="btn btn-primary">Submit</button>
                            </div>

                        </form>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;