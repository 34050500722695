import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeader from "../../../CommonComps/CommonHeader/CommonHeader";
import Accordion from "react-bootstrap/Accordion";
import CommonTitle from "../../../CommonComps/CommonTitle/CommonTitle";

const chooseUsData = [
  {
    title: "Skilled and Experienced Professional Community",
    description:
      "TechSimple ICT has a highly skilled professional team who has been serving best solutions for local and international clients for more than one decade. We are experts in information management systems development that helps the business to fulfill business objectives.",
  },
  {
    title: "Simple solution provider for digital environment",
    description:
      "TechSimple ICT will guide you to select simple solutions for your business to enhance operational efficiency and business growth. A simple solution is user friendly, secure and responsive to fulfill your business objective.",
  },
  {
    title: " Ensure Quality",
    description:
      "TechSimple ICT ensures quality based on international standards like ISO 9001, ISO 27001, ITIL, S. We work with clients in a consistent and continuous improvement process that best suits customers' needs and objectives.",
  },
  {
    title: "Secure by Design",
    description:
      "Security is always build-in with our design, development, product and service. We follow security standards like ISO 27001, NIST, OWASP. Besides that, we also try to fulfill client’s specialized requirements for security.",
  },
  {
    title: " Best Managed IT Service Provider",
    description:
      "TechSimple ICT will take care of day to day IT activities for your business so you can focus more on mainstream business. You will get best consultancy on technologies that will increase efficiency, reduce operational cost, reduce risk, ensure compliance and security.",
  },
];

const ChooseUs = () => {
  return (
    <div className="mt-2" style={{ backgroundColor: "#fff", padding: "10px 0px" }}>
      <Container>
      <CommonTitle title="WHY CHOOSE US" />
        <Row>
          <Col className="mx-auto" md={8} sm={12} xs={12}>
            {" "}
            <Accordion>
              {chooseUsData &&
                chooseUsData?.map((item, index) => {
                  return (
                    <Accordion.Item key={index} eventKey={index}>
                      <Accordion.Header>
                        {index + 1}. {item.title}
                      </Accordion.Header>
                      <Accordion.Body>{item.description}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChooseUs;
