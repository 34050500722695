import React from "react";
import TechExpertiseIcons from "../../TechExpertiseIcons/TechExpertiseIcons";
import Styles from "./SolutionDev.module.css";

const SolutionDev = () => {
  return (
    <div className="container mx-auto">
      <div className="md:flex md:justify-center">
        <div className="md:w-8/12">
          <h5 className="text-xl font-bold mb-4 tech">Our Solutions can produce</h5>
          <ul className=" mb-6">
            <li className="red-asterisk">
              Custom data to help management track a project or decision making
            </li>
            <li className="red-asterisk">Custom report data to help management team</li>
            <li className="red-asterisk">
              Real time data, particularly use when immediate action is required
            </li>
            <li className="red-asterisk">
              Archive data, particularly useful for record keeping, analysis and
              business planning
            </li>
          </ul>

          <div className="py-3">
            <h5 className="text-xl font-bold mb-2 tech" >Technology We Use</h5>
            <p className="mb-4">
              MongoDB, SQL, MySQL, Express, ReactJS, NodeJS, PHP, Java, DOT NET,
              Laravel
            </p>
            <TechExpertiseIcons />
          </div>

          <div className="py-3">
            <h5 className="text-xl font-bold mb-2 tech" >Business value</h5>
            <p className="mb-4">
              Business get following advantage from our solutions
            </p>
            <ul className="">
              <li className="red-asterisk">Operational Efficiency</li>
              <li className="red-asterisk">Cost Reduction</li>
              <li className="red-asterisk">Supply of information to decision-makers</li>
              <li className="red-asterisk">Better customer services</li>
              <li className="red-asterisk">Continuous availability of the systems</li>
              <li className="red-asterisk">Growth in communication capabilities and methods</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionDev;
