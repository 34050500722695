import React, { useContext, useEffect, useState } from 'react';
import useTitle from '../../../hooks/useTitle';
import CommonHeader from '../../CommonComps/CommonHeader/CommonHeader';
import { AllContext } from '../../../contextData/AllContext';
import Loader from '../../CommonComps/Loader/Loader';
import '../AwarnessOneComp/AwarnessOneComp.css';
import BlogCategoryTab from '../AwarnessOneComp/BlogCategoryTab/BlogCategoryTab';
import AwarenssOneDetails from '../AwarnessOneComp/AwarenssOneSections/AwarenssOneDetailsOld';
import CommonHeader2 from '../../CommonComps/CommonHeader/CommonHeader2';

const BlogPageComp = () => {
    useTitle('Blog');
    const { loading, setLoading } = useContext(AllContext);
    const [blogData, setBlogData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');

    const apiUrl = 'https://tsbackend-0514.vercel.app/api/getBlog';

    useEffect(() => {
        setLoading(true);
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                const filteredData = data.data.filter(blog => blog.status === true);
                setBlogData(filteredData);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching blog data:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Loader />;
    }

    // Filtered blog data based on selected category
    const filteredBlogData = selectedCategory === 'All' ? blogData : blogData.filter(blog => blog.category === selectedCategory);

    return (
        <div className="container mx-auto mt-24 mb-8">
            <div className="mt-44">
                <CommonHeader2 
                // shadowTitle="Blog" 
                title="Blog" />
            </div>
            <div className=" ">
                <BlogCategoryTab selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

                <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-3">
                    {filteredBlogData.map((blog, index) => (
                        <AwarenssOneDetails key={index} awarness={blog} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogPageComp;
