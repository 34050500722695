import React from 'react';
import './CRISCCourseDescription.css'

const AboutCRISCCourse = () => {
    return (
        <div className='courseDescription'>
            <div className=' my-4 text-md-start'>
                <h2 className='courseDetailsBanner py-3 text-center mt-5 mb-4 fw-bold text-3xl font-bold'>Course Description</h2>
                <h5 className='text-xl font-bold mb-2'>What is CRISC?</h5>
                <p>
                    CRISC certification is designed for IT and business professionals who have hands-on experience with risk identification, risk assessment, risk response and risk and IS control monitoring and reporting. This certification will make you a risk management expert. By studying the content of this certification, you will learn how to enhance your company’s business resilience, deliver stakeholder value and optimize Risk Management across the enterprise.
                </p>
            </div>

            <div className=' my-4'>
                <h5 className='text-xl font-bold mb-2'>Who should plan for CRISC certification? </h5>
                <ul className='list-disc pl-4 md:pl-10'>
                    <li>Senior IT Management include CXO position.</li>
                    <li>Risk Manager.</li>
                    <li>IT Security Specialist.</li>
                    <li>Senior Risk Analyst</li>
                    <li>Compliance Auditor</li>
                    <li>Security Analyst</li>
                    <li>Risk Analyst</li>
                    <li>Security Engineer</li>
                    <li>Data Protection Officer</li>

                </ul>
            </div>

            <div className=' my-4'>
                <h5 className='text-xl font-bold mb-2'>How CRISC Certification Helps in Career?</h5>

                <ul className='list-disc pl-4 md:pl-10'>
                    <li>CRISC is the #4 top paying certification worldwide.</li>
                    <li>52% CRISC certified experienced on-the-job improvement</li>
                    <li>Average annual salary for CRISC certified is US$151,000</li>
                    <li>Worldwide 30,000+ professionals holding CRISC certificate</li>
                </ul>
            </div>

            <div className=' my-4'>
                <h5 className='text-xl font-bold mb-2'>What you will learn with the CRISC certification:</h5>
                <ul className='list-disc pl-4 md:pl-10 text-blue-500'>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#1" target='_blank' rel="noreferrer" >Corporate IT Governance</a></li>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#2" target='_blank' rel="noreferrer" >IT Risk Assessment</a></li>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#3" target='_blank' rel="noreferrer" >Risk Response & Reporting</a></li>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#4" target='_blank' rel="noreferrer" >Information Technology & Security</a></li>

                </ul>
            </div>
        </div>
    );
};

export default AboutCRISCCourse;