import React from "react";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

const ScrollToTopButton = () => {
  return (
    <button
      onClick={scrollToTop}
      className="hidden md:block fixed bottom-10 right-10 hover:bg-[#977b74] bg-[#1C3664] px-4 py-2 rounded-lg text-white shadow-md border-[1px] border-gray-200 duration-300"
    >
      &#8593;
    </button>
  );
};

export default ScrollToTopButton;
