import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import CommonHeader from "../../CommonComps/CommonHeader/CommonHeader";
import CommonHeadingTwo from "../../CommonComps/CommonHeadingTwo/CommonHeadingTwo";
import cicaImg from "../../../assets/Images/training/cisa-logo.png";
import criscImg from "../../../assets/Images/training/CRISC_logo_RGB.png";
import sumImg from "../../../assets/Images/summary/TechSimpleICT Logo-03 2.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../../hooks/useTitle";
import Styles from "./TrainingComp.module.css";
import './TrainingComp.css';
import { AllContext } from "../../../contextData/AllContext";
import CommonHeader2 from "../../CommonComps/CommonHeader/CommonHeader2";

const TrainingComp = () => {
  useTitle("Tranining");
  const { setCourseName, } = useContext(AllContext);


  const handleCISACourseName = () => {
    setCourseName("CISA Certification");
  }

  const handleCRISCCourseName = () => {
    setCourseName("CRISC Certification");
  }


  const navigate = useNavigate();
  return (
    <div className="mt-44" style={{ marginBottom: "2rem" }}>
      <div className="mt-4">
        <CommonHeader2
          // shadowTitle="Training" 
          description="We conduct One-to-One, Online and Classroom based Training "
          title="Training" />
      </div>


      <div className="container bg-gray-50 shadow-md border pb-3 ">
        <CommonHeadingTwo
          title="ISACA Certification Training"
          description="TechSimple ICT is specialized in conducting Online and Classroom training for Individuals, Groups or Corporate 
        with highly skilled and certified trainers and subject matter experts."
        />
        <div className="col-lg-10 mx-auto">

          <div className=" card trainingCard mb-3">
            <div className="row g-0 flex-md-row-reverse">
              <div className="col-md-4 my-auto cardImg">
                <img src={cicaImg} className="img-fluid rounded-start px-2" alt="..." />
              </div>
              <div className="col-md-8 my-auto">
                <div className="card-body ">
                  <h5 className="card-title text-2xl md:text-3xl font-bold">CISA Training</h5>
                  <p className="card-text">Certified Information Systems Auditor® (CISA®) is world-renowned as the standard of achievement for those who audit, control, monitor and assess an organization's IT and business systems.</p>
                  <div className="mt-3 flex justify-center">
                    <Link onClick={() => handleCISACourseName()} className=" btn btn-primary btn-sm   " to="/cisaCourseDetails/CISA" >Details</Link>
                    <Link onClick={() => handleCISACourseName()} className=" btn btn-primary btn-sm ms-3   " to="/registration/CISA">Registration</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" card trainingCard mb-5 ">
            <div className="row g-0">
              <div className="col-md-4 my-auto cardImg">
                <img src={criscImg} className="img-fluid rounded-start px-2" alt="..." />
              </div>
              <div className="col-md-8 my-auto">
                <div className="card-body">
                  <h5 className="card-title text-2xl md:text-3xl font-bold">CRISC Training</h5>
                  <p className="card-text">Certified in Risk and Information Systems Control (CRISC) is a certification program that recognizes knowledge and training in the field of risk management for an organization's IT and business systems. </p>
                  <div className=" mt-3 flex justify-center">
                    <Link onClick={() => handleCRISCCourseName()} className=" btn btn-primary btn-sm    " to="/criscCourseDetails/CRISC" >Details</Link>
                    <Link onClick={() => handleCRISCCourseName()} className=" btn btn-primary btn-sm    ms-3 " to="/registration/CRISC">Registration</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* summary */}
      {/* <div className={Styles.summary__style}>
        <img src={sumImg} alt="" />
        <Container>
          <small className={Styles.bigWord__style}>We</small>
          <small
            style={{ marginLeft: "10px" }}
            className={Styles.mainDes__style}
          >
            can’t wait to build a Crreative IT Solutions, Security Solutions,
            Managed Service & GO DIGITAL for your <br/> great innovation!
          </small>{" "}
          <br />
          <div className="text-center flex justify-center">
            <Link to='/contactUs'>
            <button
              onClick={() => navigate.push("/contactUs")}
              className={`${Styles.contactBtn__style} flex justify-center items-center`}
            >
              Contact <AiOutlineArrowRight />
            </button></Link>
          </div>
        </Container>
      </div> */}
    </div>
  );
};

export default TrainingComp;
