import React, { Suspense, lazy } from "react";
import HeroSlider from "./HeroSlider/HeroSlider";
import Services from "./Services/Services";
import Styles from "./HomeComp.module.css";
import { Col, Container, Row } from "react-bootstrap";
import sumImg from "../../../assets/Images/summary/TechSimpleICT Logo-03 2.png";
import TechExpertise from "./TechExpertise/TechExpertise";
// import ChooseUs from "./ChooseUs/ChooseUs";
import useTitle from "../../../hooks/useTitle";
import Hero_1 from "./Hero/Hero_1";
import HeroCarousel from "./Hero/HeroCarousel";
import TestimonialCarousel from "./Testimonial/TestimonialCarousel";
import ProjectShowCase from "./Projects/ProjectShowCase";
import { SEO, StructuredData } from "../../../hooks/SEO";
import Loader from "../../CommonComps/Loader/Loader";

// const HeroCarousel = lazy(() => import("./Hero/HeroCarousel"))
// const Services = lazy(() => import("./Services/Services"))
// const ProjectShowCase = lazy(() => import("./Projects/ProjectShowCase"))
// const TestimonialCarousel = lazy(() => import("./Testimonial/TestimonialCarousel"))
// const TechExpertise = lazy(() => import("./TechExpertise/TechExpertise"))

const HomeComp = () => {
  useTitle("Home");

  return (
    <div className="container">
      <SEO
        title="Home Page - TechSimple ICT"
        description="Simple ICT Solution For Your Business."
        url="https://techsimpleict.com/"
      />
      <StructuredData />
      {/* <HeroSlider /> */}
      {/* <Hero/>` */}

      <Suspense fallback={<div><Loader /></div>}>
        <h1> <HeroCarousel /></h1>
        <h1> <Services /></h1>
        <h2> <ProjectShowCase /></h2>
        <h2>   <TestimonialCarousel className="container" /></h2>
        <h2>  <TechExpertise /></h2>



      </Suspense>
      {/* summary */}
      {/* <div className={Styles.summary__style}>
        <img src={sumImg} alt="" />
        <Container>
          <small className={Styles.bigWord__style}>We</small>
          <small
            style={{ marginLeft: "10px" }}
            className={Styles.mainDes__style}
          >
            provide advanced, secure and scalable web solutions to ensure proper
            utilization of resources in the business. Our solutions help you to
            achieve operational excellence, maximize productivity and taking
            right decision for the business. TechSimple ICT strongly believe in
            building partnership with the client by quality delivery.
          </small>
        </Container>
      </div> */}
      {/* Technical Expertise */}

      {/* Why Choose Us? */}

    </div>
  );
};

export default HomeComp;
