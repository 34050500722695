import { createContext, useState } from "react";

export const AllContext = createContext();

const ContextProvider = ({ children }) => {
    const [stdData, setStdData] = useState('');
    const [loading, setLoading] = useState(true);
    const [courseName, setCourseName] = useState('');

    const allInfo = {
        stdData,
        setStdData,
        courseName,
        setCourseName,
        loading,
        setLoading,
    };

    return (
        <div>
            <AllContext.Provider value={allInfo}>{children}</AllContext.Provider>
        </div>
    );
};

export default ContextProvider;
