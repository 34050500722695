import React from "react";
import Styles from "./NavbarComp.module.css";
import navLogo from "../../../assets/Images/Logos/tcs_full_logo_plain_2.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import contactIcon from "../../../assets/Images/Navbar/contactIcon.svg";
import MobileNav from "./MobileNav/MobileNav";

const NavbarComp = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 left-0 right-0 p-3 text-center z-10  ">
      <div className="navWidth mx-auto rounded-lg  backdrop-blur-lg " style={{maxWidth: "1460px"}}>
        <div className={`${Styles.mainNav__style} bg-gradient-to-r from-blue-100 via-white to-purple-100 bg-opacity-50 rounded-xl shadow-md border-[1px] border-gray-300 `}>
          <div className={Styles.leftNav__style}>
            <img
            className="w-72 flex justify-center items-center"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              src={navLogo}
              alt=""
            />
          </div>
          <div className={Styles.rightNav__style}>
            <NavLink
              className={(Active) =>
                Active?.isActive
                  ? `${Styles.activeNav__style}`
                  : `${Styles.normalNav__style}`
              }
              to="/"
            >
              HOME
            </NavLink>
            <NavLink
              className={(Active) =>
                Active?.isActive
                  ? `${Styles.activeNav__style}`
                  : `${Styles.normalNav__style}`
              }
              to="/aboutUs"
            >
              ABOUT
            </NavLink>
            <NavLink
              className={(Active) =>
                Active?.isActive
                  ? `${Styles.activeNav__style}`
                  : `${Styles.normalNav__style}`
              }
              to="/services"
            >
              SERVICES
            </NavLink>
            <NavLink
              className={(Active) =>
                Active?.isActive
                  ? `${Styles.activeNav__style}`
                  : `${Styles.normalNav__style}`
              }
              to="/training"
            >
              TRAINING
            </NavLink>
            {/* <NavLink
            className={(Active) =>
              Active?.isActive
                ? `${Styles.activeNav__style}`
                : `${Styles.normalNav__style}`
            }
            to="/go-digital"
          >
            GO DIGITAL
          </NavLink> */}
            <NavLink
              className={(Active) =>
                Active?.isActive
                  ? `${Styles.activeNav__style}`
                  : `${Styles.normalNav__style}`
              }
              to="/cybersecurity"
            >
              SECURITY-AWARNESS
            </NavLink>
            <NavLink
              className={(Active) =>
                Active?.isActive
                  ? `${Styles.activeNav__style}`
                  : `${Styles.normalNav__style}`
              }
              to="/blog"
            >
             BLOG
            </NavLink>
            <NavLink
              className={(Active) =>
                Active?.isActive
                  ? `${Styles.activeNav__style} `
                  : `${Styles.normalNav__style} `
              }
              to="/contactUs"
            >
              CONTACT
              {/* <img
              className={Styles.contactIcon__style}
              src={contactIcon}
              alt=""
            /> */}
            </NavLink>
          </div>
          <div className={Styles.mobileNav__style}>
            <MobileNav />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarComp;
