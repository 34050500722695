import React from 'react';
import './CourseDescription.css'

const AboutCISACourse = () => {
    return (
        <div className='courseDescription'>
            <div className=' my-4 text-md-start'>
                <h2 className='courseDetailsBanner py-3 text-center mt-5 mb-4 fw-bold text-3xl'>Course Description</h2>
                <h5 className='text-xl font-bold mb-2'>What is CISA?</h5>
                <p>
                    Certified Information Systems Auditor® (CISA®) is world-renowned certification for business professionals who want to audit, control and assess an organization’s IT and business systems. CISA can showcase your expertise and assert your ability to apply a risk-based approach to planning, executing and reporting on professional engagements.
                </p>
            </div>

            <div className=' my-4'>
                <h5 className='text-xl font-bold mb-2'>Who Can be Certified? </h5>
                <ul className='list-disc pl-4 md:pl-10'>
                    <li>
                        IT Auditor & Audit Professionals.
                    </li>
                    <li>
                        IT Consultants.
                    </li>
                    <li>
                        IT Security Professionals.

                    </li>
                    <li>
                        Senior Management Professionals.
                    </li>
                    <li>
                        Professionals who want to develop career on Information Security.
                    </li>
                </ul>
            </div>

            <div className=' my-4'>
                <h5 className='text-xl font-bold mb-2'>How this certification helps in career?</h5>

                <ul className='list-disc pl-4 md:pl-10'>
                    <li>
                        70% CISA Certified Professionals Experience On-The-Job Improvement.
                    </li>
                    <li>
                        22% CISA Certified Professionals receive PAY BOOST.
                    </li>
                    <li>
                        CISA Certified often make between $52,459 and $122,326 per year globally.
                    </li>
                    <li>
                        CISA Certification is GLOBALLY RECOGNIZED as Cyber Security Auditor as well as Information Security Auditor.
                    </li>
                    <li>
                        CISA provides global recognition of your skills as IS Auditor.
                    </li>
                </ul>
            </div>

            <div className=' my-4'>
                <h5 className='text-xl font-bold mb-2'>What you will learn with the CISA certification:</h5>
                <ul className='list-disc pl-4 md:pl-10 text-blue-500'>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#1" target='_blank' rel="noreferrer" >Information Systems Auditing Process</a></li>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#2" target='_blank' rel="noreferrer" >Governance and Management of Information Technology</a></li>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#3" target='_blank' rel="noreferrer" >Information Systems Acquisition, Development & Implementation</a></li>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#4" target='_blank' rel="noreferrer" >Information Systems Operations and Business Resilience</a></li>
                    <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#5" target='_blank' rel="noreferrer" >Protection of Information Assets</a></li>
                </ul>
            </div>
        </div>
    );
};

export default AboutCISACourse;