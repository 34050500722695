import React from "react";
import { Col, Row } from "react-bootstrap";
import Styles from "./TrainingAndDev.module.css";

const TrainingAndDev = () => {
  return (
    <div className="container mx-auto">
      <div className="md:flex md:justify-center">
        <div className="md:w-8/12">
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">
              TechSimple ICT provides training on
            </h5>
            <Row>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>
                  Information Security Management System (ISMS)
                </p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>
                  ISO 27001:2013 Standard
                </p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>
                  Advanced Digital Skill
                </p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>Digital Marketing</p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>IT Risk </p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>IT Audit</p>
              </Col>
            </Row>
          </div>
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">
              We provide training for
            </h5>
            <ul className="pl-5 mb-6">
              <li className="red-asterisk">Professionals like Auditor, Compliance Officer, IT</li>
              <li className="red-asterisk">Organization Head or C-suit member</li>
              <li className="red-asterisk">Government Officials</li>
              <li className="red-asterisk">Defense and Law Enforcement Agencies</li>
              <li className="red-asterisk">Entrepreneurs</li>
              <li className="red-asterisk">Young professionals</li>
            </ul>
          </div>
          <p className="py-3">
            We provide ONLINE and CLASSROOM session for individuals or
            organization to fulfill unique learning objective as required.
          </p>
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">
              Our trainers have following certification
            </h5>
            <ul className="pl-5 mb-6">
              <li className="red-asterisk">Certified Information Systems Auditor (CISA)</li>
              <li className="red-asterisk">Certified in Risk and Information Systems Control (CRISC)</li>
              <li className="red-asterisk">Certified Information Systems Security Professional (CISSP)</li>
              <li className="red-asterisk">Certified Information Security Manager (CISM)</li>
              <li className="red-asterisk">Project Management Professional (PMP)</li>
              <li className="red-asterisk">ISO 27001:2013 Lead Auditor</li>
              <li className="red-asterisk">ISO 27001:2013 Lead Implementer</li>
              <li className="red-asterisk">ISO 31000:2018 Risk Management</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingAndDev;
