import React from "react";

const Consultancy = () => {
  return (
    <div className="container mx-auto">
      <div className="md:flex md:justify-center">
        <div className="md:w-8/12">
          <h5  className="text-xl font-bold mb-4 tech">TechSimple ICT is leading Consultancy Service provider for</h5>
          <ul className=" pl-5 mb-6">
            <li className="red-asterisk">Solution Design and Development</li>
            <li className="red-asterisk">Project Management</li>
            <li className="red-asterisk">Process Optimization</li>
            <li className="red-asterisk">Information Security</li>
            <li className="red-asterisk">Software Quality Assurance and Testing</li>
            <li className="red-asterisk">Disaster Recovery and Business Continuity</li>
            <li className="red-asterisk">Audit and Assessment</li>
          </ul>

          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">Potential Customer</h5>
            <p>
              Government <span className="text-red-500">|</span> MNC <span className="text-red-500">|</span> International Organization <span className="text-red-500">|</span> Project and Program <span className="text-red-500">|</span> Development Sector <span className="text-red-500">|</span> Education Sector
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultancy;
