import React from "react";
import Lottie from "lottie-react";
import animationData from "./lottie/training.json";
import { Link } from "react-router-dom";

const Hero_2 = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="container flex flex-wrap mt-14 lg:mt-28">
      <div className="flex flex-col items-center justify-center w-full lg:w-1/2 order-last lg:order-fast">
        <div className="max-w-2xl mb-4  text-center">
          <h1 className="text-2xl font-bold leading-snug tracking-tight text-gray-800 lg:text-4xl lg:leading-tight xl:text-6xl xl:leading-tight dark:text-black lg:text-start">
            <span className="simple">Training &</span> <span className="tech">Development</span>
          </h1>
          <p className="py-3 text-base leading-normal text-gray-500 lg:text-xl xl:text-2xl dark:text-gray-500 lg:text-start">
            We provide training to individuals and communities through online or
            physical.
          </p>
          <div className="flex flex-col items-center space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row mt-4">
            <Link to="/training">
              <a
                rel="noopener"
                className="px-3 py-2 text-base lg:text-lg font-medium text-center text-white bg-[#1C3665] rounded-md flex items-center justify-center"
              >
                Learn More
                <span className="font-bold">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path>
                  </svg>
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full lg:w-1/2 lg:order-last">
        <div className="mx-auto mt-3">
          <Lottie
            animationData={animationData}
            options={defaultOptions}
            height={400}
            width={400}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero_2;
