import React from "react";

const InformationSecurity = () => {
  return (
    <div className="container mx-auto">
      <div className="md:flex md:justify-center">
        <div className="md:w-8/12">
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">We provide following services</h5>
            <ul className="pl-5 mb-6">
              <li className="red-asterisk">Information Security Management System (ISMS) Implementation</li>
              <li className="red-asterisk">ISO 27001 Audit and Assurance</li>
              <li className="red-asterisk">Vulnerability Assessment and Penetration Testing (VAPT)</li>
              <li className="red-asterisk">Information Security Policy Development</li>
              <li className="red-asterisk">Incident Management Policy Development</li>
              <li className="red-asterisk">IT Disaster Recovery and Business Continuity Policy Development</li>
              <li className="red-asterisk">Information Security Awareness Training</li>
            </ul>
          </div>
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">Our respected and prospective clients are</h5>
            <ul className="pl-5 mb-6">
              <li className="red-asterisk">Government</li>
              <li className="red-asterisk">Multi-National Companies (MNC)</li>
              <li className="red-asterisk">International Organization</li>
              <li className="red-asterisk">Financial Institutes</li>
              <li className="red-asterisk">Law Enforcement Agencies</li>
              <li className="red-asterisk">Non-Government Organization (NGO)</li>
              <li className="red-asterisk">Ongoing Project and Programs</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationSecurity;
