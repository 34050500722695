import React from "react";
import StickyBox from "react-sticky-box";

const SectionScrollTab = ({ bg, item }) => {
  console.log(item);
  return (
    <div>
      <div className="hidden md:block">
        <div className="flex mt-4 gap-4">
          <div
            className={`w-1/4 ${bg} border-r border-gray-200 rounded-3xl p-4 shadow-md border`}
          >
            <StickyBox offsetTop={200} offsetBottom={200}>
              <div>
                <h1 className="font-bold tech text-4xl">
                  {item?.title}
                </h1>
                <p>{item?.description}</p>
              </div>
            </StickyBox>
          </div>
          <div className={`flex-1 ${bg} rounded-3xl p-4 shadow-md border`}>
            {item?.pageContent}
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <div
          className={`flex flex-col ${bg} rounded-3xl p-4 shadow-md border mt-4`}
        >
          <h1 className="font-bold tech py-2 text-3xl">{item?.title}</h1>
          <p>{item?.description}</p>
          <hr className="mt-3" />
          <div className="mt-4">{item?.pageContent}</div>
        </div>
      </div>
    </div>
  );
};

export default SectionScrollTab;
