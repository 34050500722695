import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, url }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={url} />
  </Helmet>
);

const StructuredData = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "TechSimple ICT",
    "url": "https://techsimpleict.com",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://techsimpleict.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

export { SEO, StructuredData };
