import { AiOutlineArrowRight } from "react-icons/ai";
import CourseDetailsImg from '../../../../assets/Images/CourseDetails/msc-cyber-security.jpg';
import CISALogo from '../../../../assets/Images/Logos/cisa-logo.png';
import { useNavigate } from "react-router-dom";
import './CourseDetails.css'
import { useContext } from "react";
import { AllContext } from "../../../../contextData/AllContext";

export default function CISACourseDetailsBanner() {
  const { courseName, setCourseName, } = useContext(AllContext);
  setCourseName("CISA certification");
  const navigate = useNavigate();

  return (
    <div>
      <div className="slider mt-24">
        <img
          className="sliderImg"
          src={CourseDetailsImg}
          alt=""
        />
        <div className="imageOverlay">
          <div className=" d-flex">
            <div className="sliderContent">
              <h2 className="pb-2 bannerHeading fs-1">Boost your career with Globally Recognized CISA Certification</h2>
              <p className="sliderDescription">
              Prove your experience and be among the most qualified in the industry.
              </p>
              <button
                onClick={() => navigate("/registration/:id")}
                className="sliderBtn flex items-center"
              >
                <span className=" me-3"> Registration</span>
                <AiOutlineArrowRight />
              </button>
            </div>

            <div>
              <img className=" cisaLogo img-fluid" src={CISALogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
