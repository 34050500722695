import React from 'react'
import CommonHeader from '../../../CommonComps/CommonHeader/CommonHeader'
import Testimonials from './Testimonials'

const TestimonialCarousel = () => {
  return (
    <div className='py-10 mt-8'>
       <CommonHeader
          // shadowTitle="FeedBack"
          title="Clients Feedback"
          description="What Clients Says About Us"
        />
        <Testimonials/>
    </div>
  )
}

export default TestimonialCarousel