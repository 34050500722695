import React from "react";
import ContactComp from "../../../Components/UserComp/ContactComp/ContactComp";

const ContactPage = () => {
  return (
    <div>
      <ContactComp />
    </div>
  );
};

export default ContactPage;
