import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// import icon1 from "../../../assets/Images/Icons/Untitled-1.png";
// import icon2 from "../../../assets/Images/Icons/2.png";
// import icon3 from "../../../assets/Images/Icons/3.png";
// import icon4 from "../../../assets/Images/Icons/4.png";
// import icon5 from "../../../assets/Images/Icons/5.png";
// import icon6 from "../../../assets/Images/Icons/6.png";
// import icon7 from "../../../assets/Images/Icons/7.png";
// import icon8 from "../../../assets/Images/Icons/8.png";
// import icon9 from "../../../assets/Images/Icons/9.png";
// import icon10 from "../../../assets/Images/Icons/10.png";
// import icon11 from "../../../assets/Images/Icons/11.png";
// import icon12 from "../../../assets/Images/Icons/12.png";
// import icon13 from "../../../assets/Images/Icons/13.png";
// import icon14 from "../../../assets/Images/Icons/14.png";
// import icon15 from "../../../assets/Images/Icons/15.png";

import icon1 from "../../../assets/Images/Icons/net.png";
import icon2 from "../../../assets/Images/Icons/java.png";
import icon3 from "../../../assets/Images/Icons/php.png";
import icon4 from "../../../assets/Images/Icons/JavaScript-logo.png";
import icon5 from "../../../assets/Images/Icons/mongo.png";
import icon6 from "../../../assets/Images/Icons/react.png";
import icon7 from "../../../assets/Images/Icons/express.png";
import icon8 from "../../../assets/Images/Icons/nodejs.png";
// import icon9 from "../../../assets/Images/Icons/mongoose.png";
import icon10 from "../../../assets/Images/Icons/mysql.png";
import icon11 from "../../../assets/Images/Icons/jira.png";
import icon12 from "../../../assets/Images/Icons/trello.svg";
import icon13 from "../../../assets/Images/Icons/atlassian.png";

import { Col, Container, Row } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import Styles from "./TechExpertiseIcons.module.css";

const techIcons = [
  {
    img: icon1,
    title: ".NET",
  },
  {
    img: icon2,
    title: "Java",
  },
  {
    img: icon3,
    title: "PHP",
  },
  {
    img: icon4,
    title: "JavaScript",
  },
  {
    img: icon5,
    title: "MongoDB",
  },
  {
    img: icon6,
    title: "React",
  },
  {
    img: icon7,
    title: "Express",
  },
  {
    img: icon8,
    title: "Node.js",
  },
  // {
  //   img: icon9,
  //   title: "Mongoose",
  // },
  {
    img: icon10,
    title: "MySQL",
  },

  {
    img: icon11,
    title: "JIRA",
  },

  {
    img: icon12,
    title: "Trello",
  },
  {
    img: icon13,
    title: "Atlassian",
  },
];

const TechExpertiseIcons = () => {
  return (
    <div>
      <Container>
        <Row>
          {techIcons &&
            techIcons.map((item, index) => (
              <Col
                className="py-2 px-2 text-center d-flex align-items-center justify-content-center"
                key={index}
                md={1}
                lg={1}
                sm={3}
                xs={3}
              >
                <Tooltip title={item?.title} placement="top-start" arrow>
                  <img
                    className={Styles.techIcon__style}
                    src={item.img}
                    alt=""
                  />
                </Tooltip>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default TechExpertiseIcons;
