import React from "react";
import CommonHeader from "../../../CommonComps/CommonHeader/CommonHeader";

const projects = [
  {
    title: "Bangladesh Police Service Association (BPSA)",
    description:
      "The BPSA project aims to enhance the efficiency and connectivity of police officers across Bangladesh through a centralized platform for information sharing, resources, and community engagement.",
    imageUrl: "https://i.ibb.co/6bQrYsx/bpsa-logo-removebg.png",
    liveUrl: "https://bpsaweb.police.gov.bd/",
  },
  {
    title: "Jahangirnagar University BCS Officers Forum (JUBOF)",
    description:
      "The JUBOF project is designed to create a robust network for BCS officers from Jahangirnagar University, fostering collaboration, support, and professional development opportunities.",
    imageUrl: "https://i.ibb.co/GTwKhFH/blob.png",
    liveUrl: "https://www.jubofbd.com/",
  },
];

const ProjectShowCase = () => {
  return (
    <div className=" py-10 mt-10">
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        <CommonHeader
          // shadowTitle="Projects"
          title="Our Respected Clients "
          description="Honored to serve diverse and esteemed clients with excellence and dedication."
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-4">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg overflow-hidden"
            >
              <div className="p-6 flex flex-col md:flex-row">
                <div className="flex-shrink-0 flex justify-center">
                  <img
                    src={project.imageUrl}
                    alt={project.title}
                    className="w-36 h-full object-contain md:w-48"
                  />
                </div>
                <div className="mt-4 md:mt-0 md:ml-6">
                  <p className="text-gray-600">{project.description}</p>
                </div>
              </div>
              <div className="p-6 border-t gap-2 flex flex-col md:flex-row justify-between items-center">
              <h2 className="text-lg md:text-xl font-bold text-gray-800 lg:!text-left text-center">

                  {project.title}
                </h2>
                <a
                  href={project.liveUrl}
                  className="mt-2 md:mt-0 bg-blue-500 whitespace-nowrap text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Demo
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectShowCase;

// import React from 'react';
// import CommonHeader from '../../../CommonComps/CommonHeader/CommonHeader';

// const projects = [
//   {
//     title: 'Bangladesh Police Service Association (BPSA)',
//     description: 'The BPSA project aims to enhance the efficiency and connectivity of police officers across Bangladesh through a centralized platform for information sharing, resources, and community engagement.',
//     imageUrl: 'https://i.ibb.co/rFhHWGH/image.png',
//     liveUrl: 'https://bpsaweb.police.gov.bd/',
//     githubUrl: 'https://github.com/user/project-one'
//   },
//   {
//     title: 'Jahangirnagar University BCS Officers Forum (JUBOF)',
//     description: 'The JUBOF project is designed to create a robust network for BCS officers from Jahangirnagar University, fostering collaboration, support, and professional development opportunities.',
//     imageUrl: 'https://i.ibb.co/1GjGy65/image.png',
//     liveUrl: 'https://www.jubofbd.com/',
//     githubUrl: 'https://github.com/user/project-one'
//   }
// ]

// const ProjectShowCase = () => {
//   return (
//     <div className="min-h-screen py-10 ">
//       <div className="container mx-auto px-6 md:px-12 lg:px-24">
//       <CommonHeader
//         shadowTitle="Projects"
//         title="Our Projects"
//         description="Discover our innovative solutions designed to enhance connectivity and foster collaboration."
//       />
//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-5">
//           {projects.map((project, index) => (
//             <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all hover:scale-105 hover:shadow-2xl">
//               <img src={project.imageUrl} alt={project.title} className="w-full h-64 object-fit border" />
//               <div className="p-8">
//                 <h2 className="text-3xl font-bold mb-4 text-gray-800">{project.title}</h2>
//                 <p className="text-gray-600 mb-6">{project.description}</p>
//                 <div className="flex justify-between items-center">
//                   <a
//                     href={project.liveUrl}
//                     className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     Live Demo
//                   </a>
//                   <a
//                     href={project.githubUrl}
//                     className="bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-900 transition"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     GitHub
//                   </a>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProjectShowCase;
