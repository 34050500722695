import React from "react";

const AssessmentAssurance = () => {
  return (
    <div className="md:w-8/12 mx-auto">
      <div className="mx-auto py-3">
        <h5 className="text-xl font-bold mb-2 tech" >
          Our certified professionals will generate report covering all
          aspects of your IT environment, including:
        </h5>
        <p>
          Delivery Performance{" "}
          <span className="text-red-500">|</span> Ease of Use{" "}
          <span className="text-red-500">|</span> Security{" "}
          <span className="text-red-500">|</span> Collaboration{" "}
          <span className="text-red-500">|</span> Cloud Computing{" "}
          <span className="text-red-500">|</span> Disaster Recovery{" "}
          <span className="text-red-500">|</span> Compliance
        </p>
      </div>
      <div className="py-3">
        <h5 className="text-xl font-bold mb-2 tech">TechSimple ICT is leading Consultancy Service provider for</h5>
        <ul className=" pl-5">
          <li className="red-asterisk">Testing and Quality Assurance during development</li>
          <li className="red-asterisk">Vulnerability Assessment and Penetration Testing (VAPT)</li>
          <li className="red-asterisk">Security and Performance Testing</li>
        </ul>
      </div>
      <div className="py-3">
        <h5 className="text-xl font-bold mb-2 tech">Our respected and prospective clients are</h5>
        <ul className=" pl-5">
          <li className="red-asterisk">Government</li>
          <li className="red-asterisk">Multi-National Companies (MNC)</li>
          <li className="red-asterisk">International Organization</li>
          <li className="red-asterisk">Financial Institutes</li>
          <li className="red-asterisk">Law Enforcement Agencies</li>
          <li className="red-asterisk">Non-Government Organization (NGO)</li>
          <li className="red-asterisk">Ongoing Project and Programs</li>
        </ul>
      </div>
    </div>
  );
};

export default AssessmentAssurance;
