import { useContext } from "react";
import './Registration.css';
import useTitle from "../../../hooks/useTitle";
import { AllContext } from "../../../contextData/AllContext";

const RegistrationDetailsPage = () => {
    useTitle("RegistrationDetails");
    const { stdData, setStdData } = useContext(AllContext);

    const { courseName, trainingType, courseFee, name, email, phone, presentAddress, companyName, comments } = stdData;


    // console.log("RegistrationDetailsPage : ", stdData);
    return (
        <div className="col-lg-6 col-md-9 mx-auto mt-3 ">
            <h2 className='courseDetailsBanner py-3 text-center mt-3 mb-1 fw-bold '>Registration Details</h2>

            <div className=" regDetailsBody px-4 py-2">
                <div className=" my-2 regDetailsBody">
                    <h4><b>Course Name:</b> {courseName}</h4>
                    {
                        trainingType == 'classroomBased' ? <>
                            <h5><b> Training Type: </b>Classroom Based,<b> Fees: </b>{courseFee}{courseFee != "contact with trainer" && <span>TK</span>} </h5>
                        </> : trainingType == 'onlineBased' ? <h5><b> Training Type: </b>Online Based,<b> Fees: </b>{courseFee}{courseFee != "contact with trainer" && <span>TK</span>} </h5> : <h5><b> Training Type: </b>One to One,<b> Fees: </b>{courseFee}{courseFee != "contact with trainer" && <span>TK</span>} </h5>
                    }
                    <h5 className=" mt-4">Student Data</h5>
                    <p className=" my-0"> <b> Name:</b> {name}</p>
                    <p className=" my-0"> <b> Phone:</b> {phone}</p>
                    <p className=" my-0"> <b> Email:</b> {email}</p>
                    <p className=" my-0"> <b> Present Address: </b> {presentAddress} </p>

                    {companyName &&
                        <p className=" my-0"> <b> Company Name:</b> {companyName} </p>
                    }
                    {comments &&
                        <p className=" my-0"> <b> Comments:</b> {comments} </p>
                    }
                </div>

                <div className=" my-4">
                    <h6 className=" fw-bold text-success">To confirm your seat on NEXT BATCH or for more detail about the training, please communicate at 01711082532 (WhatsApp)</h6>
                    {/* <h6 className=" fw-bold text-success">To book a seat in the next batch please confirm your registration by paying the course fees by bKash or Bank transfer</h6>
                    <h6 className=" fw-bold text-black">BKash No- 01234567890 and DutchBanglaBank AccoutNo: 234542342342</h6> */}
                </div>
                <h5 className=" my-4 fw-bold text-primary text-center">For any query please call at +8801711082532 (Whatsapp)</h5>
            </div>
        </div>
    );
};

export default RegistrationDetailsPage;