import React, { useEffect, useRef } from 'react';
import './CircleTrail.css';

const CircleTrail = () => {
  const circleRef = useRef(null);

  
  useEffect(() => {
    const circle = circleRef.current;
    let mouseX = 0, mouseY = 0;
    let currentX = 0, currentY = 0;
    const speed = 0.9;

    const onMouseMove = (e) => {
      mouseX = e.clientX - 15; 
      mouseY = e.clientY - 15; 
    };

    const onMouseEnterClickable = () => {
      if (circle) {
        circle.classList.add('vanish');
      }
    };

    const onMouseLeaveClickable = () => {
      if (circle) {
        circle.classList.remove('vanish');
      }
    };

    const animate = () => {
      currentX += (mouseX - currentX) * speed;
      currentY += (mouseY - currentY) * speed;

      if (circle) {
        circle.style.transform = `translate3d(${currentX}px, ${currentY}px, 0)`;
      }

      requestAnimationFrame(animate);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.querySelectorAll('a, button, [role="button"]').forEach((element) => {
      element.addEventListener('mouseenter', onMouseEnterClickable);
      element.addEventListener('mouseleave', onMouseLeaveClickable);
    });

    animate();

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.querySelectorAll('a, button, [role="button"]').forEach((element) => {
        element.removeEventListener('mouseenter', onMouseEnterClickable);
        element.removeEventListener('mouseleave', onMouseLeaveClickable);
      });
    };
  }, []);

  return <div className="circle-trail hidden lg:block" ref={circleRef}></div>;
};

export default CircleTrail;
