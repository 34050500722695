import React from 'react';
import { FaHandPointRight } from 'react-icons/fa';
import './CourseDescription.css';

const CISACourseFee = () => {
    return (
        <div className=' '>
            <div className=' my-4'>
                <h2 className='courseDetailsBanner py-3 text-center mt-5 mb-4 fw-bold text-3xl font-bold'>Course Fee</h2>
                <p className='flex gap-2'>
                    <FaHandPointRight className=' mb-1 fs-3 me-1' />
                    <b className='  trainingSubHead'>Classroom Based Training: 18,000.00 Taka. </b>
                </p>
                <div className='mt-2 mb-3'>Course Duration: 48 Hours <br />
                    Total Classes: 07, Only on Weekend from 10:00 AM to 6:00 PM</div>

                <p className='flex gap-2'>
                    <FaHandPointRight className='mb-1 fs-3 me-1' />
                    <b className=' trainingSubHead'> Online Based Training: 15,000.00 Taka.</b><br />
                </p>
                <div className='mt-2 mb-3'>
                    Course Duration: 48 Hours <br />
                    Total Classes: 10, 04 Hours Per class, Sunday and Wednesday from 07:00 PM to 11:00 PM. <br />
                    Class can be scheduled as per participants choice and Trainer’s availability.</div>

                <p className='flex gap-2'>
                    <FaHandPointRight className='mb-1 fs-3 me-1' />
                    <b className=' trainingSubHead'> One To One Training: Please contact with trainer for further details. </b>
                </p>
            </div>
        </div>
    );
};

export default CISACourseFee; 