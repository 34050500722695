import React, { useState, useEffect } from "react";
import "./ServiceSection.css";
import img1 from "../../../../assets/Images/cyberSecurity/1.measurement.webp";
import img2 from "../../../../assets/Images/cyberSecurity/2.cybersecurity strategyandpolicy.webp";
import img3 from "../../../../assets/Images/cyberSecurity/3.cybersecurityawarenesscampaign.webp";
import img4 from "../../../../assets/Images/cyberSecurity/4. cybersecurity awarenessdrill.webp";
import img5 from "../../../../assets/Images/cyberSecurity/5.cybersecurityawarenessprogram.webp";
import img6 from "../../../../assets/Images/cyberSecurity/6.cybersecurityawarenesseLearningprogram.webp";
import img7 from "../../../../assets/Images/cyberSecurity/7.phishingsimulat.webp";

const ServiceSection = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  const handleFirstImageLoad = () => {
    setFirstImageLoaded(true);
  };
  const services = [
    {
      name: "Organization’s CyberSecurity Status Measurement",
      image: img1,
    },
    {
      name: "CyberSecurity Strategy/Police Development",
      image:img2,
    },
    {
      name: "CyberSecurity Awareness Campaign",
      image:
       img3,
    },
    {
      name: "Awareness Drill - Physical, Online and Custom",
      image:
        img4,
    },
    {
      name: "CyberSecurity Awareness Program",
      image:
       img5,
    },
    {
      name: "CyberSecurity Awareness eLearning",
      image:
       img6,
    },
    {
      name: "Phishing Simulation and Training",
      image:
       img7,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % services.length;
      setCurrentIndex(nextIndex);
      setSelectedService(services[nextIndex]);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, services]);
  useEffect(() => {
    const imagePromises = services.map((service) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = service.image;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setSelectedService(services[currentIndex]);
        setFirstImageLoaded(true);
      })
      .catch((error) => {
        console.error("Error preloading images:", error);
      });
  }, []);

  const handleServiceSelect = (index) => {
    setCurrentIndex(index);
    setSelectedService(services[index]);
  };

  return (
    <div className="">
      <div className="mt-5 flex justify-center mb-3">
        <p className="border-b-2 border-b-[#EA2126] inline text-3xl text-center">
          Our Awesome Services
        </p>
      </div>
      <div className="flex justify-center flex-col md:flex-row items-center md:gap-20">
        {/* Right Side: List of Services */}
        <div className="service-list hidden md:block">
          {/* Display list of services */}
          <ul className="text-gray-700 p-4">
            {services.map((service, index) => (
              <li
                key={index}
                className={`mb-2 cursor-pointer border border-gray-300 rounded-md py-3 px-4 ${
                  index === currentIndex
                    ? "text-white font-bold bg-gray-800"
                    : "text-gray-700 hover:text-blue-500"
                }`}
                onClick={() => handleServiceSelect(index)}
              >
                {service.name}
              </li>
            ))}
          </ul>
        </div>
        {/* Left Side: Image with transition effect */}
        <div className="image-container hidden md:block">
          {selectedService && (
            <div
              className={`image-slide ${!firstImageLoaded ? "loading" : ""}`}
            >
              <img
                src={selectedService.image}
                alt={selectedService.name}
                className="hidden md:block rounded-md shadow-sm img-fluid w-full h-auto md:max-w-[500px] md:max-h-[400px]"
                onLoad={handleFirstImageLoad}
              />

              <div className="image-indicator">
                {/* Display dots to indicate images */}
                {services.map((_, index) => (
                  <span
                    key={index}
                    className={`hidden md:block dot ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => handleServiceSelect(index)}
                  ></span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* mobile screen  */}
      <div className="flex justify-center flex-col md:flex-row items-center md:gap-20">
        {/* Right Side: List of Services */}
        <div className="service-list md:hidden">
          {/* Display list of services */}
          <ul className="text-gray-700 p-4">
            {services.map((service, index) => (
              <li
                key={index}
                className={`mb-2 cursor-pointer border border-gray-300 rounded-md py-3 px-4 `}
                onClick={() => handleServiceSelect(index)}
              >
                {service.name}
              </li>
            ))}
          </ul>
        </div>
        {/* Left Side: Image with transition effect */}
        <div className="image-container md:hidden">
          {selectedService && (
            <div
              className={`image-slide ${!firstImageLoaded ? "loading" : ""}`}
            >
              <img
                src={selectedService.image}
                alt={selectedService.name}
                className="hidden md:block rounded-md shadow-sm img-fluid w-full h-auto md:max-w-[500px] md:max-h-[400px]"
                onLoad={handleFirstImageLoad}
              />

              <div className="image-indicator">
                {/* Display dots to indicate images */}
                {services.map((_, index) => (
                  <span
                    key={index}
                    className={`hidden md:block dot ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => handleServiceSelect(index)}
                  ></span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
