import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import HomeComp from "../Components/UserComp/HomeComp/HomeComp";
import AboutPage from "../Pages/UserPages/AboutPage/AboutPage";
import ContactPage from "../Pages/UserPages/ContactPage/ContactPage";
import ServicesPage from "../Pages/UserPages/ServicesPage/ServicesPage";
import TrainingPage from "../Pages/UserPages/TrainingPage/TrainingPage";
import NotFoundPage from "../Components/CommonComps/NotFoundPage/NotFoundPage";
import CRISCCourseDetails from "../Pages/UserPages/CourseDetails/CRISC/CRISCCourseDetails";
import CISACourseDetails from "../Pages/UserPages/CourseDetails/CISA/CISACourseDetails";
import CybersecurityAwarenessPage from "../Pages/UserPages/CybersecurityAwareness/CybersecurityAwarenessPage";
import RegistrationPage from "../Pages/UserPages/RegistrationPages/RegistrationPage";
import RegistrationDetailsPage from "../Pages/UserPages/RegistrationPages/RegistrationDetailsPage";
import Awarness2023Display from "../Pages/UserPages/Awarness/Awarness2023Display";
import Awarness2023DataEntry from "../Pages/UserPages/Awarness/Awarness2023DataEntry";
import BlogPage from "../Pages/UserPages/BlogPage/BlogPage";
import BlogPageDetails from "../Pages/UserPages/BlogPage/BlogPageDetails";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout></MainLayout>,
        children: [
            {
                path: "/",
                element: <HomeComp></HomeComp>
            },
            {
                path: "/aboutUs",
                element: <AboutPage></AboutPage>
            },
            {
                path: "/contactUs",
                element: <ContactPage></ContactPage>
            },
            {
                path: "/services",
                element: <ServicesPage></ServicesPage>
            },
            {
                path: "/training",
                element: <TrainingPage></TrainingPage>
            },
            {
                path: "/cisaCourseDetails/:id",
                element: <CISACourseDetails></CISACourseDetails>
            },
            {
                path: "/criscCourseDetails/:id",
                element: <CRISCCourseDetails></CRISCCourseDetails>
            },

            {
                path: "/registration/:id",
                element: <RegistrationPage></RegistrationPage>
            },
            {
                path: "/regDetails/:id",
                element: <RegistrationDetailsPage></RegistrationDetailsPage>
            },
            {
                path: "/cybersecurity",
                element: <CybersecurityAwarenessPage></CybersecurityAwarenessPage>
            },
            {
                path: "/awarnessOneDataEntry",
                element: <Awarness2023DataEntry></Awarness2023DataEntry>
            },
            {
                path: "/blog",
                element: <BlogPage/>
            },
            {
                path: "/blog/:id",
                element: <BlogPageDetails/>
            },
            {
                path: "*",
                element: <NotFoundPage></NotFoundPage>
            },

        ]
    }
])

export default router;