
import React from 'react';

const CISARegistrationTop = () => {
    
    return (
        <div>
            <h2 className='courseDetailsBanner py-3 text-center mt-lg-5  mt-md-4 mt-2  mb-4 fw-bold px-1 text-3xl font-bold'>What is the CISA difference?</h2>
            <p className=" fs-5 lh-sm"> Certified Information Systems Auditor® (CISA®) is world-renowned as the standard of achievement for those who audit, control, monitor and assess an organization’s IT and business systems. If you are a mid-career professional, CISA can showcase your expertise and assert your ability to apply a risk-based approach to planning, executing and reporting on audit engagements.</p>

            <h2 className='courseDetailsBanner py-3 text-center mt-5 mb-4 fw-bold text-3xl font-bold'>What you will learn with the <br/> CISA certification</h2>

            <div className=' col-12 d-lg-flex regDetailLink '>
                <div className=" col-lg-6 my-0">
                    <ul className="my-0 list-disc pl-4 md:pl-10 text-blue-500">
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#1" target='_blank' rel="noreferrer" >Information Systems Auditing Process</a></li>
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#3" target='_blank' rel="noreferrer" >Information Systems Acquisition, Development & Implementation</a></li>
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#5" target='_blank' rel="noreferrer" >Protection of Information Assets</a></li>
                    </ul>
                </div>

                <div className=" col-lg-6 ">
                    <ul className="my-0 list-disc pl-4 md:pl-10 text-blue-500">
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#2" target='_blank' rel="noreferrer" >Governance and Management of Information Technology</a></li>
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/cisa/cisa-exam-content-outline#4" target='_blank' rel="noreferrer" >Information Systems Operations and Business Resilience</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CISARegistrationTop;