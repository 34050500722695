import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./NotFoundPage.module.css";
import notFoundImg from "../../../assets/Images/NotFound/notfound.jpg";
import useTitle from "../../../hooks/useTitle";

const NotFoundPage = () => {
  useTitle('Wrong Route');

  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/');
    }, 1 * 3 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <div>
      <div className={Styles.mainDiv__style}>
        <div className={Styles.childDiv__style}>
          <img className={Styles.notFoundImg__style} src={notFoundImg} alt="" />
          {/* <h1>404 !</h1>
          <h3>OPPS! PAGE NOT FOUND</h3> */}
          <h5 style={{ textAlign: "justify" }} className="py-3">
            Sorry, the page you're looking for doesn't exist. If you think
            something is broken, report a problem.
          </h5>
          <button onClick={() => navigate("/")} className="commonBtn__style">
            Got To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
