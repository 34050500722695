import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero1 from "./Hero_1";
import Hero2 from "./Hero_2";
import Hero3 from "./Hero_3";

const HeroCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "20px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-10 container">
      {/* Illustration behind hero content */}
      {/* <div
        className="hidden lg:block absolute left-[-30%] top-[60%] transform -translate-x-1/2 bottom-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#EBF3FA" offset="0%" />
              <stop stopColor="#8EB6DC" offset="77.402%" />
              <stop stopColor="#8EB6DC" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle
              cx="1232"
              cy="128"
              r="128"
              className="bg-[#EBF3FA] bg-opacity-10"
            />
            <circle
              cx="155"
              cy="443"
              r="64"
              className="bg-[#fea591] bg-opacity-10"
            />
          </g>
        </svg>
      </div> */}

      {/* Illustration behind hero content */}
      {/* <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]"></div> */}
      <div className="max-w-screen-2xl mx-auto">
        <Slider {...settings}>
          <div className="">
            <div className="hero-content">
              <Hero3 />
            </div>
        </div>
          <div className="">
            <div className="hero-content">
              <Hero2 />
            </div>
          </div>
          <div className="">
            <div className="hero-content">
              <Hero1 />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default HeroCarousel;
