import React from 'react';

const WhyChooseTechSimpleICT = () => {
  const reasons = [
    {
      title: <>Tailored <span className='text-blue-700'> Training Content</span></>,
      description: <>TechSimple ICT provides customized training content designed to be highly effective for specific audiences.</>,
    },
    {
      title: <>Optimal <span className='text-blue-700'> Tools & Techniques</span></>,
      description: <>TechSimple ICT applies precise tools, techniques, and metrics to execute impactful cybersecurity awareness initiatives.</>,
    },
    {
      title: <>Follows <span className='text-blue-700'>International Standards</span> </>,
      description: <>TechSimple ICT's Cybersecurity Awareness Program meets international standards, ensuring top-notch security and compliance.</>,
    },
    {
      title: (
        <>
          Partnership with <span className="text-[#292929] uppercase">Tree</span>
          <span className="text-[#82C34A] uppercase">Solution</span>
        </>
      ),
      description: (
        <>
          TechSimple ICT Cybersecurity Awareness Program is powered by{' '}
          <span className="text-[#292929] uppercase">Tree</span>
          <span className="text-[#82C34A] uppercase">Solution</span> - Security Through Awareness.
        </>
      ),
    },
    {
      title: <>Decade of <span className='text-blue-700'>Experience</span> </>,
      description: <>With a decade of expertise, TechSimple ICT has effectively partnered with diverse organizations to enhance Security Awareness.</>,
    },
    {
      title: <>Focus on <span className='text-blue-700'>Partnership & Long-term Relationships</span> </>,
      description: <>At TechSimple ICT, we prioritize partnership and strive to build long-term relationships with all our clients.</>,
    },
  ];
  
  

  return (
    <div className="bg-[#F0F6FB] py-12 px-4 sm:px-6 lg:px-8 shadow-md">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-8">
          Why Choose{' '}
          <span className="textTech text-4xl">Tech</span>
          <span className="textSimple pe-2 text-4xl">Simple</span>
          <span className="textICT text-4xl">ICT</span> for <span className="">Cybersecurity</span> <span className="">Awareness</span>?
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {reasons.map((reason, index) => (
            <div key={index} className="bg-white rounded-lg  shadow-lg p-6">
              <h3 className="text-xl font-bold text-gray-800 mb-4">{reason.title}</h3>
              <p className="text-gray-600">{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseTechSimpleICT;
