import React from 'react';

const CRISCRegistrationTop = () => {
    return (
        <div>
            <h2 className='courseDetailsBanner py-3 text-center mt-lg-5  mt-md-4 mt-2  mb-4 fw-bold px-1 text-3xl font-bold '>What is the CRISC difference?</h2>
            <p className=" fs-5 lh-sm">
                A Certified in Risk and Information Systems Control® (CRISC®) certification will make you a Risk Management expert. Studying a proactive approach based on Agile methodology, you’ll learn how to enhance your company’s business resilience, deliver stakeholder value and optimize Risk Management across the enterprise.</p>

            <h2 className='courseDetailsBanner py-3 text-center mt-5 mb-4 fw-bold text-3xl font-bold'>What you will learn with the <br/> CRISC certification</h2>

            <div className=' col-12 d-lg-flex regDetailLink '>
                <div className=" col-lg-6 my-0">
                    <ul className="my-0 list-disc pl-4 md:pl-10 text-blue-500">
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#1" target='_blank' rel="noreferrer" >CRISC Certification</a></li>
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#2" target='_blank' rel="noreferrer" >IT Risk Assessment</a></li>

                    </ul>
                </div>

                <div className=" col-lg-6 ">
                    <ul className="my-0 list-disc pl-4 md:pl-10 text-blue-500">
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#3" target='_blank' rel="noreferrer" >Risk Response & Reporting</a></li>
                        <li><a className=' text-decoration-none' href="https://www.isaca.org/credentialing/crisc/crisc-exam-content-outline#4" target='_blank' rel="noreferrer" >Information Technology & Security</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CRISCRegistrationTop;
