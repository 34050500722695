import React from "react";
import Styles from "./CommonTitle.module.css";

const CommonTitle = ({ title }) => {
  return (
    <div className="py-3 pt-">
      <h2 className={`${Styles.title__Style} text-3xl mb-2`}>{title}</h2>
      <div className={Styles.titleBorder__style}></div>
    </div>
  );
};

export default CommonTitle;
