import { AiOutlineArrowRight } from "react-icons/ai";
import CourseDetailsImg from '../../../../assets/Images/CourseDetails/crix-banner.jpg';
import CRISCLogo from '../../../../assets/Images/Logos/crisc-logo.png';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AllContext } from "../../../../contextData/AllContext";
import './CRISCCourseDetails.css'

export default function CRISCCourseDetailsBanner() {
  const { courseName, setCourseName, } = useContext(AllContext);
  setCourseName("CRISC Certification");
  const navigate = useNavigate();
  
  return (
    <div>
      <div className="slider mt-24">
        <img
          className="sliderImg"
          src={CourseDetailsImg}
          alt=""
        />
        <div className="imageOverlay">
          <div className=" d-flex">
            <div className="sliderContent">
              <h2 className="pb-2 bannerHeading fs-1">Get Ahead in Risk and Information Systems Control</h2>
              <p className="sliderDescription">
              A CRISC certification helps you stay one step ahead of real-world threats across your enterprise.
              </p>
              <button
                onClick={() => navigate("/registration/:id")}
                className="sliderBtn flex items-center"
              >
                <span className=" me-3"> Registration</span>
                <AiOutlineArrowRight />
              </button>
            </div>

            <div>
              <img className=" cisaLogo img-fluid" src={CRISCLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
