import React from "react";
import Styles from "./CommonHeader.module.css";

const CommonHeader = ({ shadowTitle, title, description }) => {
  return (
    <div>
      <h1 className={Styles.shadowHeading__stye}>{shadowTitle}</h1>
      <h1 className={Styles.mainHeading__stye}>{title}</h1>
      <div className={Styles.headingBorder__style}></div>

      {/* <hr className={Styles.headerUnderline__style} /> */}
      <p className="commonHeader text-center pt-1 pb-3" style={{ fontWeight: 400 }}>
        {description}
      </p>
    </div>
  );
};

export default CommonHeader;
