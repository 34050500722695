import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const CyberSecuritySubHeading = ({ title, description1, description2, description3, description4, description5 }) => {
  return (
    <div>
      <div
        style={{ backgroundColor: "#F0F6FB", margin: " " }}
        className=" py-5"
      >
        <div className="container">
          <div className="col-lg-10 mx-auto">
            <Container>
              {/* <h1 className=" text-center mb-3">{title}</h1> */}
              <h1 className="text-2xl
              md:text-4xl text-center mb-3 font-bold"><span className=""><span className="">Cybersecurity</span> <span className="">Awareness</span></span> Month – October</h1>
              <p className="mb-3">{description1}</p>
              <p className="mb-3">{description2}</p>
              <p className="mb-3">{description3}</p>
              <p>{description4}</p>
              <p>{description5}</p>
              <ul className="pl-4 lg:pl-10">
                <li className="list-disc ">
                  <Link className="text-blue-500 text-decoration-none" to="https://staysafeonline.org/online-safety-privacy-basics/passwords-securing-accounts/" target="_blank" >Use strong passwords </Link>
                  and a
                  <Link className="text-blue-500 text-decoration-none" to="https://staysafeonline.org/online-safety-privacy-basics/password-managers/" target="_blank" > password manager</Link>
                </li>
                <li className="list-disc"><Link className="text-blue-500 text-decoration-none" to="https://staysafeonline.org/online-safety-privacy-basics/multi-factor-authentication/" target="_blank" >Turn on multifactor authentication</Link> </li>
                <li className="list-disc"><Link className="text-blue-500 text-decoration-none" to="https://staysafeonline.org/theft-fraud-cybercrime/phishing/" target="_blank">Recognize and report phishing</Link> </li>
                <li className="list-disc"><Link className="text-blue-500 text-decoration-none" to="https://staysafeonline.org/resources/software-updates/" target="_blank">Update software</Link> </li>
              </ul>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CyberSecuritySubHeading;
