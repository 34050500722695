import React from "react";
import Styles from "./ItServices.module.css";
import { Col, Row } from "react-bootstrap";

const ItServices = () => {
  return (
    <div className="container mx-auto">
      <div className="md:flex md:justify-center">
        <div className="md:w-8/12">
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">
              Our managed service offers the following features
            </h5>
            <ul className="list-none">
              <li className="red-asterisk">Highly skilled and professional staff</li>
              <li className="red-asterisk">Service Management following international standard</li>
              <li className="red-asterisk">Incident Management</li>
              <li className="red-asterisk">End-to-end security assurance</li>
            </ul>
          </div>
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">We provide managed service for </h5>
            <Row>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>Data Management</p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>
                  Software or Solution Management
                </p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>Network Management</p>
              </Col>
              <Col className="py-1" md={4} sm={6} xs={12}>
                <p className={Styles.trainingItem__style}>
                  Infrastructure Management{" "}
                </p>
              </Col>
            </Row>
          </div>

          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">Business Value</h5>
            <ul className="list-none">
              <li className="red-asterisk">Business can Focus more on the main business</li>
              <li className="red-asterisk">Service Cost Reduction</li>
              <li className="red-asterisk">High-Level Support</li>
              <li className="red-asterisk">Efficiency and Security</li>
              <li className="red-asterisk">Proactive Monitoring and Alerting</li>
            </ul>
          </div>
          <div className="py-3">
            <h5 className="text-xl font-bold mb-4 tech">Our respected and prospective clients are</h5>
            <p>
              Government{" "}
              <small style={{ color: "red", padding: "0px 5px" }}> | </small>{" "}
              Startup and SME
              <small style={{ color: "red", padding: "0px 5px" }}> | </small>
              Non-Government Organization (NGO)
              <small style={{ color: "red", padding: "0px 5px" }}> | </small>{" "}
              Ongoing Project and Programs
              <small style={{ color: "red", padding: "0px 5px" }}> | </small>
              Industries
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItServices;
